export const circular = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="circularDef" width="24" height="24" rx="12" fill="#73e4cc" />
</svg>

export const rectangular = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22">
    <rect id="RectangularDef" width="24" height="22" rx="3" fill="#73e4cc" />
</svg>

export const point = <svg xmlns="http://www.w3.org/2000/svg" width="38" height="35" viewBox="0 0 38 35">
    <g id="plotIconDef" transform="translate(4.077 7.185)">
        <path id="Path_3936" data-name="Path 3936" d="M2,27.784H19.408l8.93-16.363L13.635,1.129,2,9.742Z" transform="translate(1.346 -4.414)" fill="#73e4cc" stroke="#73e4cc" strokeWidth="1.5" />
        <circle id="Ellipse_1321" data-name="Ellipse 1321" cx="4" cy="4" r="4" transform="translate(10.923 -6.185)" fill="#73e4cc" stroke="#03403a" strokeWidth="2" />
        <circle id="Ellipse_1325" data-name="Ellipse 1325" cx="4" cy="4" r="4" transform="translate(24.923 2.815)" fill="#73e4cc" stroke="#03403a" strokeWidth="2" />
        <circle id="Ellipse_1322" data-name="Ellipse 1322" cx="4" cy="4" r="4" transform="translate(15.923 18.815)" fill="#73e4cc" stroke="#03403a" strokeWidth="2" />
        <circle id="Ellipse_1323" data-name="Ellipse 1323" cx="4" cy="4" r="4" transform="translate(-3.077 18.815)" fill="#73e4cc" stroke="#03403a" strokeWidth="2" />
        <circle id="Ellipse_1324" data-name="Ellipse 1324" cx="4" cy="4" r="4" transform="translate(-3.077 2.815)" fill="#73e4cc" stroke="#03403a" strokeWidth="2" />
    </g>
</svg>

export const inputPin = <svg xmlns="http://www.w3.org/2000/svg" width="13.525" height="18.017" viewBox="0 0 13.525 18.017">
    <path id="pin" d="M10.045,15.582A13,13,0,0,1,7.2,17.936a.4.4,0,0,1-.457.015,16.055,16.055,0,0,1-3.959-3.532A12.574,12.574,0,0,1,.122,8.671a7.63,7.63,0,0,1,.954-5.376A6.618,6.618,0,0,1,2.55,1.662,7.177,7.177,0,0,1,7.062,0a6.353,6.353,0,0,1,4.281,1.748,6.31,6.31,0,0,1,1.142,1.391A8.525,8.525,0,0,1,13.3,9.3a13.555,13.555,0,0,1-3.253,6.278v0ZM6.762,3.483A3.365,3.365,0,1,1,3.4,6.848,3.365,3.365,0,0,1,6.762,3.483Z" transform="translate(0 0.001)" fill="#c4fff9" fillRule="evenodd" />
</svg>


export const descriptionInput = <svg id="description" xmlns="http://www.w3.org/2000/svg" width="13.316" height="18.016" viewBox="0 0 13.316 18.016">
    <g id="Group_4193" data-name="Group 4193">
        <g id="Group_1020" data-name="Group 1020">
            <path id="Path_1500" data-name="Path 1500" d="M1.084.479h7.83c.613,0,3.955,3.368,3.955,3.981V16.19c0,.613-.075,1.513-.688,1.513H1.084c-.613,0-.848-.528-.848-1.141V1.275C.236.662.471.479,1.084.479Z" transform="translate(0.026 0.052)" fill="#c4fff9" />
            <path id="iconfinder_12.File_290138" d="M18.75,19.016H8.567A1.567,1.567,0,0,1,7,17.45V2.567A1.567,1.567,0,0,1,8.567,1h7.442a.386.386,0,0,1,.3.155L20.162,5a.384.384,0,0,1,.155.3h0V17.45A1.567,1.567,0,0,1,18.75,19.016ZM16.4,2.324V4.917h2.592ZM19.533,5.7H16.008a.392.392,0,0,1-.392-.392V1.783H8.567a.784.784,0,0,0-.783.783V17.45a.784.784,0,0,0,.783.783H18.75a.784.784,0,0,0,.783-.783Zm-2.35,10.183h-7.05a.392.392,0,1,1,0-.783h7.05a.392.392,0,1,1,0,.783Zm0-3.133h-7.05a.392.392,0,1,1,0-.783h7.05a.392.392,0,1,1,0,.783Zm0-3.133h-7.05a.392.392,0,1,1,0-.783h7.05a.392.392,0,0,1,0,.783Z" transform="translate(-7 -1)" fill="#165f59" fillRule="evenodd" />
        </g>
    </g>
</svg>

export const plotIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21">
    <g id="plotIcon" transform="translate(1.113 -0.369)">
        <path id="Path_3936" data-name="Path 3936" d="M2,22.752H14.165L19.49,9.684,2,9.276Z" transform="translate(0.345 -5.269)" fill="#c4fff9" stroke="#c4fff9" strokeWidth="1.5" />
        <circle id="Ellipse_1321" data-name="Ellipse 1321" cx="3" cy="3" r="3" transform="translate(15.887 1.369)" fill="#c4fff9" stroke="#003a35" strokeWidth="2" />
        <circle id="Ellipse_1322" data-name="Ellipse 1322" cx="3" cy="3" r="3" transform="translate(11.887 14.369)" fill="#c4fff9" stroke="#003a35" strokeWidth="2" />
        <circle id="Ellipse_1323" data-name="Ellipse 1323" cx="3" cy="3" r="3" transform="translate(-0.113 14.369)" fill="#c4fff9" stroke="#003a35" strokeWidth="2" />
        <circle id="Ellipse_1324" data-name="Ellipse 1324" cx="3" cy="3" r="3" transform="translate(-0.113 1.369)" fill="#c4fff9" stroke="#003a35" strokeWidth="2" />
    </g>
</svg>

export const deleteIcon = <svg id="deleteDef" xmlns="http://www.w3.org/2000/svg" width="13.155" height="16.841" viewBox="0 0 13.155 16.841">
    <g id="delete">
        <path id="Shape" d="M.94,14.969A1.881,1.881,0,0,0,2.819,16.84h7.517a1.881,1.881,0,0,0,1.879-1.871V3.742H.94V14.969ZM13.155.936H9.867L8.927,0h-4.7l-.94.936H0V2.807H13.155V.936Z" fill="#73e4cc" fillRule="evenodd" />
    </g>
    <rect id="Rectangle_2959" data-name="Rectangle 2959" width="1.037" height="13.582" transform="translate(6.059 3.259)" fill="#065a53" />
    <rect id="Rectangle_2962" data-name="Rectangle 2962" width="1.037" height="13.582" transform="translate(2.948 3.259)" fill="#065a53" />
    <rect id="Rectangle_2960" data-name="Rectangle 2960" width="1.037" height="13.582" transform="translate(9.169 3.259)" fill="#065a53" />
    <rect id="Rectangle_2961" data-name="Rectangle 2961" width="0.799" height="12.441" transform="translate(12.799 2.976) rotate(90)" fill="#065a53" />
</svg>

export const cropsIcon = <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23" height="23" viewBox="0 0 23 23">
    <defs>
    <clipPath id="clip-path">
        <rect id="Rectangle_1508" data-name="Rectangle 1508" width="23" height="23" transform="translate(0 1)" fill="#73e4cc"/>
    </clipPath>
    </defs>
    <g id="cropDef" transform="translate(0 -1)" clip-path="url(#clip-path)">
    <g id="crop_plant" data-name="crop plant" transform="translate(-62.194 -517.36)">
        <path id="Path_634" data-name="Path 634" d="M0,.819C4.842,9.271,10.069,9.932,14.983,6.238a16.026,16.026,0,0,0,1.432-1.25,6.521,6.521,0,0,1-2.695.672c-.792.048-1.273-.391-2.522-.783a17.984,17.984,0,0,0-5.036-1.15,10.138,10.138,0,0,1,3.2.259L7.79,0A11.193,11.193,0,0,1,0,.819Z" transform="matrix(0.588, 0.809, -0.809, 0.588, 68.462, 521.578)" fill="#73e4cc" stroke="#03403a" strokeWidth="1"/>
        <path id="Path_633" data-name="Path 633" d="M6.1,0c1.443,6.422,7.483,14.628-2.164,17.013,2.068-1.25,1.667-6.9,1.857-9.066-.667,2.65-.346,8.13-3.23,8.864C-4.381,10.274,4.838,5.068,6.1,0Z" transform="translate(73.98 520.398) rotate(14)" fill="#73e4cc" stroke="#03403a" strokeWidth="1"/>
    </g>
    </g>
</svg>


