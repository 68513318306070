import { useErrorHandler } from 'react-error-boundary'
import { useDispatch } from 'react-redux'

const useHandleErrorHook = () => {

    const handleError = useErrorHandler();
    const dispatch = useDispatch()
    const setIsSubscribedState = (state: any) => dispatch({ type: 'set', isSubscribed: state })

    function handleErrorHelper(error: any) {
        if ((error as any).code !== 401 && (error as any).code !== 402) {
            handleError(error)
        }
        if ((error as any).code === 402) {
            setIsSubscribedState(false)
        }
    }
    return { handleErrorHelper }
}
export default useHandleErrorHook