
import { FC, useState } from 'react'
import { LightDropDownSelectorProps } from './index.interface'
const LightDropDownSelector: FC<LightDropDownSelectorProps> = ({ list, handleSelection, selectedItemIndex }) => {
    const [expand, setExpand] = useState<boolean>(false);
    return (
        <div className={expand ? 'selector-component-wrapper expanded' : 'selector-component-wrapper'}>
            <button className='selector-button' onClick={() => { setExpand(!expand) }}>
                {list[selectedItemIndex].name}
                <div className='button-triangle' />
            </button>
            {expand && <ul className='selector-list'>
                {list.map((listItem: any, index: number) =>
                    <li key={listItem.name} onClick={() => { handleSelection(index); setExpand(false) }} className={selectedItemIndex === index ? 'selector-list-item selected' : 'selector-list-item'}>{listItem.value}</li>
                )}
            </ul>}
        </div>
    )
}

export default LightDropDownSelector
