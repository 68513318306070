import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as turf from "@turf/turf";
import { getEnv } from "env";
import { fromSquareMeterToAcres } from "utils/unitConversions";
import { CircleMode, DragCircleMode, DirectMode } from "mapbox-gl-draw-circle";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import { dateToBackendDateString } from "services/formating";
import useScreenDimensions from "utils/useScreenDimensions";
import { SMALL_SCREEN_WIDTH } from "utils/config";
import { expandURL } from "./api";
import { MAP_SEARCH_MODES } from "utils/config";
import Loader from "components/Loading";
import { URLRegex, isNumeric } from "utils/regex";
import useHandleErrorHook from "utils/useHandleErrorHook";

if (mapboxgl.getRTLTextPluginStatus() === "unavailable")
  mapboxgl.setRTLTextPlugin(
    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
    //@ts-ignore
    null,
    localStorage.getItem("i18nextLng") === "ar" ? true : false
  );
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
(mapboxgl as any).accessToken = process.env.REACT_APP_API_MAPTOKEN;

const useMap = (
  t: Function,
  plotsList: any,
  organizationDetails: any,
  addNewPlot: Function,
  enableDrawing: boolean,
  setEnableDrawing: Function,
  updatePlot: Function
) => {
  const { screenDimensions } = useScreenDimensions();
  const geocoderRef = useRef<any>();
  const [selectedSearchMethod, setSelectedSearchMethod] = useState<number>(0);
  const selectedSearchMethodRef = useRef<number>(0);
  const loadingLongURL = useRef<boolean>(false);
  const { handleErrorHelper } = useHandleErrorHook();
  const [map, setMap] = useState<any>();
  const mapInstance = useRef<any>(null);
  const mapRef = useRef<any>(null);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [controlAdded, setControlAdded] = useState<boolean>(false);
  const [center, setCenter] = useState<any>();
  const [drawingMethod, setDrawingMethod] = useState<string>("");
  const [acresUsed, setAcresUsed] = useState<number>(0);
  const [currentMapMode, setCurrentMapMode] = useState({
    id: "1",
    value: undefined,
  });
  const [firstSearchTime, setFirstSearchTime] = useState<boolean | undefined>(
    undefined
  );
  const [layerLoading, setLayerLoading] = useState(false);
  const [isDropdownFocused, setIsDropdownFocused] = useState(false);
  const [wgsCoordinates, setWgsCoordinates] = useState<any>({});
  const [coordinates, setCoordinates] = useState<any>({});
  const drawingMode = useRef<any>(null);
  const plotsRef = useRef(null);
  const acresRef = useRef<any>(null);
  const lang = localStorage.getItem("i18nextLng") || "en";
  const defaultZoomLevel = 14;
  var deleteUnsubmittedObserverInstance = useRef<any>(null);
  var deleteSubmittedObserverInstance = useRef<any>(null);
  var submitPlotObserverInstance = useRef<any>(null);
  var editPlotObserverInstance = useRef<any>(null);
  var drawRectObserverInstance = useRef<any>(null);
  var drawCircleObserverInstance = useRef<any>(null);
  var drawPointObserverInstance = useRef<any>(null);

  plotsRef.current = plotsList;

  let modes = MapboxDraw.modes;
  let custom_select: any = modes.direct_select;
  let DrawPolygon: any = modes.draw_polygon;
  let SimpleSelectMode: any = modes.simple_select;
  const [smallScreen, setSmallScreen] = useState(
    window.outerWidth <= SMALL_SCREEN_WIDTH
  );

  const smallScreenRef = useRef<boolean>(
    window.outerWidth <= SMALL_SCREEN_WIDTH
  );

  function handleWGSCoordinates(dir: string, value: string) {
    let newState = { ...wgsCoordinates, [dir]: value };
    setWgsCoordinates(newState);
    if (
      newState.dN &&
      newState.mN &&
      newState.sN &&
      newState.dE &&
      newState.mE &&
      newState.sE &&
      isNumeric.test(newState.dN) &&
      isNumeric.test(newState.mN) &&
      isNumeric.test(newState.sN) &&
      isNumeric.test(newState.dE) &&
      isNumeric.test(newState.mE) &&
      isNumeric.test(newState.sE)
    ) {
      let lat =
        Number(newState.dN) +
        Number(newState.mN) / 60 +
        Number(newState.sN) / (60 * 60);
      let long =
        Number(newState.dE) +
        Number(newState.mE) / 60 +
        Number(newState.sE) / (60 * 60);
      geocoderRef.current.setInput(`${lat},${long}`);
    }
  }

  function handleCoordinates(dir: string, value: string) {
    let newState = { ...coordinates, [dir]: value };
    setCoordinates(newState);
    if (
      newState.lat &&
      newState.long &&
      isNumeric.test(newState.lat) &&
      isNumeric.test(newState.long)
    ) {
      geocoderRef.current.setInput(
        `${Number(newState.lat)},${Number(newState.long)}`
      );
    }
  }

  function handleSelectedSearchMethod(m:number){
    setSelectedSearchMethod(m);
    setCoordinates({});
    setWgsCoordinates({});
    geocoderRef.current.setInput(
      ""
    );
  }

  useEffect(() => {
    selectedSearchMethodRef.current = selectedSearchMethod;
    let placeholderText: string;
    switch (selectedSearchMethodRef.current) {
      case MAP_SEARCH_MODES.LOCATION:
        placeholderText = t("searchPlaceholder");
        break;
      case MAP_SEARCH_MODES.LINK:
        placeholderText = t("pasteUrl");
        break;
      default:
        placeholderText = t("searchPlaceholder");
        break;
    }
    geocoderRef.current?.setPlaceholder(placeholderText);
  }, [selectedSearchMethod]);

  useEffect(() => {
    smallScreenRef.current = smallScreen;
  }, [smallScreen]);
  useEffect(() => {
    setSmallScreen(screenDimensions.width <= SMALL_SCREEN_WIDTH);
  }, [screenDimensions.width]);
  const searchHandler = (e: any, mapVar: any) => {
    if (e.result.center) {
      setCenter(e.result.center);
      mapVar.flyTo({
        center: e.result.center,
        zoom: defaultZoomLevel,
      });
    }
    if (firstSearchTime === undefined) {
      setFirstSearchTime(true);
    }
  };
  const searchHandlerRef = useRef(searchHandler);
  useEffect(() => {
    if (firstSearchTime) {
      searchHandlerRef.current = searchHandler;
    }
  }, [firstSearchTime]);

  DirectMode.clickInactive = () => {};
  DragCircleMode.clickInactive = () => {};
  DrawRectangle.clickInactive = () => {};
  DrawPolygon.clickInactive = () => {};
  custom_select.clickInactive = () => {};
  SimpleSelectMode.clickInactive = () => {};
  SimpleSelectMode.clickAnywhere = () => {};
  DirectMode.clickAnywhere = () => {};

  const { onClick: _onClick } = DirectMode;
  const { onClick: _onClickCustom } = custom_select;

  DirectMode.onClick = function (...args: any) {
    if (args[1]?.featureTarget?.properties?.active === "true")
      _onClick.apply(this, args);
    return;
  };

  const { onDrag: _onDrag } = DirectMode;

  DirectMode.onDrag = function (...args: any) {
    if (args[1]?.featureTarget?.properties?.active === "false") return;
    _onDrag.apply(this, args);
  };
  custom_select.onClick = function (...args: any) {
    if (args[1]?.featureTarget?.properties?.active === "true")
      _onClickCustom.apply(this, args);
    return;
  };
  SimpleSelectMode.onClick = (state: any, e: any) => {};

  // Mobile tap handling
  const { onTap: _onTap } = SimpleSelectMode;
  SimpleSelectMode.onTap = function (...args: any) {
    if (args[1]?.featureTarget?.properties?.active === "false") return;
    _onTap.apply(this, args);
  };
  const { onTap: _onTap2 } = DirectMode;

  DirectMode.onTap = function (...args: any) {
    if (!args[1]?.featureTarget) return;
    _onTap2.apply(this, args);
  };

  const { onTap: _onTap3 } = custom_select;

  custom_select.onTap = function (...args: any) {
    if (!args[1]?.featureTarget) return;
    _onTap3.apply(this, args);
  };

  let draw: any = new MapboxDraw({
    modes: {
      ...MapboxDraw.modes,
      draw_circle: CircleMode,
      drag_circle: DragCircleMode,
      direct_select: DirectMode,
      draw_rectangle: DrawRectangle,
      custom_select: custom_select,
      draw_polygon: DrawPolygon,
      simple_select: SimpleSelectMode,
    },
    boxSelect: false,
    userProperties: true,
    styles: [
      {
        id: "gl-draw-polygon-fill-inactive",
        type: "fill",
        filter: [
          "all",
          ["==", "active", "false"],
          ["==", "$type", "Polygon"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "fill-color": [
            "match",
            ["get", "user_isValidArea"],
            "false",
            "#ff3030",
            "true",
            "#fdd15a",

            /* other */ "#fdd15a",
          ],
          "fill-outline-color": [
            "match",
            ["get", "user_isValidArea"],
            "false",
            "#ff3030",
            "true",
            "#fdd15a",

            /* other */ "#fdd15a",
          ],
          "fill-opacity": 0.3,
        },
      },
      {
        id: "gl-draw-polygon-fill-active",
        type: "fill",
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        paint: {
          "fill-color": [
            "match",
            ["get", "user_isValidArea"],
            "false",
            "#ff3030",
            "true",
            "#fdd15a",

            /* other */ "#fdd15a",
          ],
          "fill-outline-color": [
            "match",
            ["get", "user_isValidArea"],
            "false",
            "#ff3030",
            "true",
            "#fdd15a",

            /* other */ "#fdd15a",
          ],
          "fill-opacity": 0.3,
        },
      },
      {
        id: "gl-draw-polygon-midpoint",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
        paint: {
          "circle-radius": 3,
          "circle-color": "transparent",
        },
      },
      {
        id: "gl-draw-polygon-stroke-inactive",
        type: "line",
        filter: [
          "all",
          ["==", "active", "false"],
          ["==", "$type", "Polygon"],
          ["!=", "mode", "static"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": [
            "match",
            ["get", "user_isValidArea"],
            "false",
            "#ff3131",
            "true",
            "#ffc02e",

            /* other */ "#ffc02e",
          ],
          "line-width": 3,
        },
      },
      {
        id: "gl-draw-polygon-stroke-active",
        type: "line",
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": [
            "match",
            ["get", "user_isValidArea"],
            "false",
            "#ff3131",
            "true",
            "#ffc02e",

            /* other */ "#ffc02e",
          ],
          "line-dasharray": [0.2, 2],
          "line-width": 3,
        },
      },

      {
        id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": 8,
          "circle-color": "#fff",
        },
      },
      {
        id: "gl-draw-line",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "active", "true"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": [
            "match",
            ["get", "user_isValidArea"],
            "false",
            "#ff3131",
            "true",
            "#ffc02e",

            /* other */ "#ffc02e",
          ],
          "line-dasharray": [0.2, 2],
          "line-width": 2,
        },
      },
      {
        id: "gl-draw-polygon-and-line-vertex-active",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": 6,
          "circle-color": [
            "match",
            ["get", "user_isValidArea"],
            "false",
            "#ff3030",
            "true",
            "#fdd15a",
            /* other */ "#fdd15a",
          ],
        },
      },
    ],

    defaultMode: "simple_select",
    controls: {
      trash: false,
    },
  });
  const getLongUrl: Function = async (link: string) => {
    try {
      const longUrl: any = await expandURL(link);
      if (longUrl?.data?.statusCode === 200) return longUrl.data.payload;
      return null;
    } catch (e: any) {
      handleErrorHelper(e);
    }
  };

  const coordinatesGeocoder = function (query: any) {
    // // Match anything which looks like
    // // decimal degrees coordinate pair.

    const matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[(,|‚) ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );

    const matchesLink = query.match(/!3d(-?\d+(?:\.\d+)?)!4d(-?\d+(?:\.\d+))/);

    let lat;
    let lng;
    const geocodes: any = [];

    if (
      selectedSearchMethodRef.current === MAP_SEARCH_MODES.COORDINATES &&
      matches
    ) {
      lat = Number(matches[1]);
      lng = Number(matches[2]);
      geocodes.push(coordinateFeature(lng, lat));
      return geocodes;
    } else {
      if (selectedSearchMethodRef.current === MAP_SEARCH_MODES.LOCATION) {
        return null;
      } else if (
        selectedSearchMethod === MAP_SEARCH_MODES.LINK &&
        matchesLink &&
        matchesLink[0]
      ) {
        lat = Number(matchesLink[1]);
        lng = Number(matchesLink[2]);
        mapInstance.current.flyTo({
          center: [lng,lat],
          zoom: defaultZoomLevel,
        });
        // geocodes.push(coordinateFeature(lng, lat));
        // return geocodes;
      } else {
        if (
          selectedSearchMethodRef.current === MAP_SEARCH_MODES.LINK &&
          URLRegex.test(query)
        ) {
          loadingLongURL.current = true;
          getLongUrl(query).then((long: any) => {
            if (long) {
              let matches = long
              .match(/!3d(-?\d+(?:\.\d+)?)!4d(-?\d+(?:\.\d+))/)
            lat = Number(matches[1]);
            lng = Number(matches[2]);
            mapInstance.current.flyTo({
              center: [lng,lat],
              zoom: defaultZoomLevel,
            });
            }
            loadingLongURL.current = false;
            return geocodes;
          });
        } else return null;
      }
    }

    function coordinateFeature(lng: any, lat: any) {
      return {
        center: [lng, lat],
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
        place_name: lat + "‚" + lng,
        place_type: ["coordinate"],
        properties: {},
        type: "Feature",
      };
    }
  };

  useEffect(() => {
    if (mapRef?.current) {
      const mapVar = new mapboxgl.Map({
        container: mapRef.current,
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        center: center,
        attributionControl: false,
        zoom: 2,
        // locale: lang,
        preserveDrawingBuffer: true,
      });

      mapVar.on("idle", function () {
        mapVar.resize();
      });
      mapVar.on("load", () => {
        let labels = [
          "country-label",
          "state-label",
          "settlement-label",
          "settlement-subdivision-label",
          "airport-label",
          "poi-label",
          "water-point-label",
          "water-line-label",
          "natural-point-label",
          "natural-line-label",
          "waterway-label",
          "road-label",
        ];

        labels.forEach((label) => {
          mapVar.setLayoutProperty("country-label", "text-field", [
            "get",
            `name_${lang}`,
          ]);
        });

        let geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          localGeocoder: coordinatesGeocoder as any,
          reverseGeocode: true,
          filter: function (item) {
            if (
              selectedSearchMethodRef.current === MAP_SEARCH_MODES.COORDINATES
            )
              return item.place_type[0] === "coordinate";
            if (selectedSearchMethodRef.current === MAP_SEARCH_MODES.LOCATION)
              return item.place_type[0] === "poi" || item.place_type[0] === "address" || item.place_type[0] === "place";
            if (selectedSearchMethodRef.current === MAP_SEARCH_MODES.LINK)
              return false;
            return true;
          },
          placeholder: t("searchPlaceholder"),
        });

        mapVar.dragRotate.disable();
        mapVar.touchZoomRotate.disableRotation();
        mapVar.addControl(
          new mapboxgl.NavigationControl(),
          lang === "en" ? "top-left" : "top-right"
        );

        let el = document?.getElementById("geocoder");
        if (el && !el?.hasChildNodes()) {
          geocoder.addTo("#geocoder");
          // Add geocoder result to container.
          geocoder.on("result", (e: any) => {
            searchHandlerRef.current(e, mapVar);
          });
          geocoder.on("results", (e: any) => {
            const noResults = document.querySelector(
              ".mapbox-gl-geocoder--no-results"
            ) as HTMLElement;

            if (selectedSearchMethodRef.current === MAP_SEARCH_MODES.LINK) {
              if (noResults && loadingLongURL.current) {
                noResults.innerHTML = "Loading..";
              }
            }
          });
          // Clear results container when search is cleared.
          geocoder.on("clear", () => {});
          geocoder.on("error", function (e: any) {
            if (
              e.error.statusCode == 422 &&
              selectedSearchMethodRef.current === MAP_SEARCH_MODES.LINK
            ) {
              let matches = e.error.request.params.query
              .match(/!3d(-?\d+(?:\.\d+)?)!4d(-?\d+(?:\.\d+))/)

              if(matches[1] && matches[2]){
              let lat = Number(matches[1]);
              let lng = Number(matches[2]);
              mapInstance.current.flyTo({
                center: [lng,lat],
                zoom: defaultZoomLevel,
              });
            }
            }
          });
        }
        mapInstance.current = mapVar;
        setMap(mapVar);
        geocoderRef.current = geocoder
        setMapLoaded(true);
      });
    }
  }, [mapRef, organizationDetails]);
  useEffect(() => {
    if (mapRef?.current && currentMapMode.value === t("farmLatestImage")) {
      setLayerLoading(true);
      const date = dateToBackendDateString(new Date());
      if (!map.getSource("sentinel-hub-tiles")) {
        map?.addSource("sentinel-hub-tiles", {
          // 'coordinates': infoArray[i].bbox,
          type: "raster",
          tiles: [
            `https://services.sentinel-hub.com/ogc/wms/${
              getEnv().SENTINEL_HUB_INSTANCE_ID
            }?showLogo=false&service=WMS&request=GetMap&layers=TRUE-COLOR-S2L2A&preset=TRUE-COLOR-S2L2A&styles=&format=image%2Fjpeg&transparent=true&version=1.3.0&tiles=true&maxcc=100&time=${date}&height=512&width=512&srs=EPSG%3A3857&bbox={bbox-epsg-3857}`,
          ],
          tileSize: 512,
        });
        map?.addLayer({
          id: "sentinel-hub-tiles",
          type: "raster",
          source: "sentinel-hub-tiles",
          paint: {
            "raster-fade-duration": 0,
          },
          layout: {
            // Make the layer visible by default.
            visibility: "visible",
          },
          minzoom: 0,
          maxzoom: 22,
        });
        // to render drawing layers above sentinel-hub layer
        if (
          map?.getLayer("gl-draw-polygon-and-line-vertex-stroke-inactive.cold")
        ) {
          map.moveLayer(
            "sentinel-hub-tiles",
            "gl-draw-polygon-and-line-vertex-stroke-inactive.cold"
          );
        }
        if (map?.getLayer("gl-draw-polygon-fill-inactive.cold")) {
          map.moveLayer(
            "sentinel-hub-tiles",
            "gl-draw-polygon-fill-inactive.cold"
          );
        }
      } else {
        map.setLayoutProperty("sentinel-hub-tiles", "visibility", "visible");
      }
    }
    if (
      mapRef?.current &&
      currentMapMode.value === t("farmHigherResolution") &&
      map.getLayer("sentinel-hub-tiles")
    ) {
      setLayerLoading(true);
      map.setLayoutProperty("sentinel-hub-tiles", "visibility", "none");
    }
    map?.on("idle", () => {
      setLayerLoading(false);
    });
  }, [mapRef, currentMapMode]);
  function updatePositionSuccess(position: any) {
    map.flyTo({
      center: [position.coords.longitude, position.coords.latitude],
      zoom: defaultZoomLevel,
    });
    setCenter([position.coords.longitude, position.coords.latitude]);
  }
  function updatePositionFailure(error: any) {
    setCenter([0, 0]);
  }
  function getLocation() {
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
      };
      navigator.geolocation.getCurrentPosition(
        updatePositionSuccess,
        updatePositionFailure,
        options
      );
    } else {
      setCenter([0, 0]);
    }
  }
  useEffect(() => {
    setDrawingMethod("simple_select");
    drawingMode.current = "simple_select";
    return () => {
      mapInstance?.current?.removeControl(myCustomControl);
      setMap(undefined);
    };
  }, []);

  function updateArea(e: any) {
    const data = draw.getAll();
    let updatedObject = e.features[0];
    if (updatedObject?.properties?.status !== "static") {
      let area = fromSquareMeterToAcres(turf.area(data));
      let remaining: number = Number(
        (
          organizationDetails?.allowedAcres -
          (organizationDetails?.consumedAcres + area)
        ).toFixed(2)
      );
      if (remaining <= 0) {
        updatedObject.properties.isValidArea = "false";
        draw.setFeatureProperty(updatedObject.id, "isValidArea", "false");
      } else {
        updatedObject.properties.isValidArea = "true";
        draw.setFeatureProperty(updatedObject.id, "isValidArea", "true");
      }
      draw.set(draw.getAll());
      acresRef.current = area;
      setAcresUsed(area);
      updatePlot(plotsRef.current, updatedObject);
    }
  }

  function modeChanged(e: any) {
    if (
      e.mode === "simple_select" &&
      drawingMode.current === "draw_polygon" &&
      draw.getSelected()?.features?.length === 0
    ) {
      draw.changeMode(drawingMode.current);
    }
  }

  function createArea(e: any) {
    const data = draw.getAll();
    let newObj = e.features[0];
    newObj.properties.status = "active";
    let area = fromSquareMeterToAcres(turf.area(data));
    let remaining: number = Number(
      (
        organizationDetails?.allowedAcres -
        (organizationDetails?.consumedAcres + area)
      ).toFixed(2)
    );
    if (remaining <= 0) {
      newObj.properties.isValidArea = "false";
      draw.setFeatureProperty(newObj.id, "isValidArea", "false");
    } else {
      newObj.properties.isValidArea = "true";
      draw.setFeatureProperty(newObj.id, "isValidArea", "true");
    }
    draw.set(draw.getAll());
    setAcresUsed(area);
    acresRef.current = area;
    addNewPlot(newObj);
    setEnableDrawing(false);
  }

  useEffect(() => {
    if (mapLoaded && !controlAdded) {
      map.addControl(draw);
      map.addControl(myCustomControl, lang === "en" ? "top-right" : "top-left");
      map.on("draw.create", createArea);
      map.on("draw.update", updateArea);
      map.on("draw.modechange", modeChanged);
      setControlAdded(true);
    }
  }, [mapLoaded, enableDrawing]);

  useEffect(() => {
    if (mapLoaded) getLocation();
  }, [mapLoaded]);
  class MyCustomControl {
    rectButton: any;
    circButton: any;
    pointButton: any;
    shapesContainer: any;
    draw: any;

    constructor(opt: any) {
      let ctrl = this;
      ctrl.draw = opt.draw;
    }

    onAdd(map: any) {
      let ctrl = this;
      ctrl.draw.deleteAll();
      ctrl.shapesContainer = document.getElementById("shapes-container");
      const deleteSubmittedObserver = () => {
        const el: any = document.querySelectorAll(
          "#delete-submitted-plot-button"
        );
        el?.forEach((element: any) => {
          if (element) {
            element.addEventListener("click", (e: any) => {
              let id = element.parentElement.id;
              ctrl.draw.delete(id);
              const data = draw.getAll();
              let area = fromSquareMeterToAcres(turf.area(data));
              acresRef.current = area;
              setAcresUsed(area);
            });
          }
        });
      };

      const deleteUnsubmittedObserver = () => {
        const el: any = document.querySelector(
          "#delete-unsubmitted-plot-button"
        );
        if (el) {
          el.addEventListener("click", (e: any) => {
            let id = el.parentElement.id;
            ctrl.draw.delete(id);
            const data = draw.getAll();
            let area = fromSquareMeterToAcres(turf.area(data));
            acresRef.current = area;
            setDrawingMethod("");
            setAcresUsed(area);
            setEnableDrawing(true);
          });
        }
      };

      const rectClickHandler = function () {
        ctrl.draw.changeMode("draw_rectangle");
        drawingMode.current = "draw_rectangle";
      };
      const circleClickHandler = function () {
        const zoom = map.getZoom();
        ctrl.draw.changeMode("drag_circle", {
          initialRadiusInKm: 1 / Math.pow(2, zoom - 11),
        });
        drawingMode.current = "drag_circle";
        ctrl.draw.delete("-96.5801808656236544.76489866786821");
      };
      const pointClickHandler = function () {
        ctrl.draw.changeMode("draw_polygon");
        drawingMode.current = "draw_polygon";
      };

      const drawRectObserver = () => {
        const drawButton: any = document.querySelector("#rectangle-mode-draw");
        const rectButton: any = document.querySelector("#rectangle-mode");

        if (smallScreenRef.current) {
          rectButton?.removeEventListener("click", rectClickHandler, true);
          drawButton?.addEventListener("click", rectClickHandler, true);
        } else {
          drawButton?.removeEventListener("click", rectClickHandler, true);
          rectButton?.addEventListener("click", rectClickHandler, true);
        }
      };
      const drawCircleObserver = () => {
        const drawButton: any = document.querySelector("#circle-mode-draw");
        const circleButton: any = document.querySelector("#circle-mode");

        if (smallScreenRef.current) {
          circleButton?.removeEventListener("click", circleClickHandler, true);
          drawButton?.addEventListener("click", circleClickHandler, true);
        } else {
          drawButton?.removeEventListener("click", circleClickHandler, true);
          circleButton?.addEventListener("click", circleClickHandler, true);
        }
      };
      const drawPointObserver = () => {
        const drawButton: any = document.querySelector("#point-mode-draw");
        const pointButton: any = document.querySelector("#point-mode");

        if (smallScreenRef.current) {
          pointButton?.removeEventListener("click", pointClickHandler, true);
          drawButton?.addEventListener("click", pointClickHandler, true);
        } else {
          drawButton?.removeEventListener("click", pointClickHandler, true);
          pointButton?.addEventListener("click", pointClickHandler, true);
        }
      };
      const submitObserver = () => {
        const el: any = document.querySelector("#plot-submit-button");
        if (el) {
          let id = el.parentElement.id;
          var feat = draw.get(id);
          // let isValidId = draw.getAll()?.features?.some((feature: any) => { return feature?.id === id });
          if (id) {
            if (feat?.properties?.isCircle)
              ctrl.draw.changeMode("direct_select", { featureId: id || "" });
            else ctrl.draw.changeMode("custom_select", { featureId: id || "" });
            el.addEventListener("click", (e: any) => {
              var currFeat = draw.get(id);
              ctrl.draw.setFeatureProperty(id, "active", "false");
              draw.delete(id);
              draw.add(currFeat);
              ctrl.draw.changeMode("simple_select");
              drawingMode.current = "simple_select";
              setDrawingMethod("simple_select");
            });
          }
        }
      };

      const editObserver = () => {
        const el: any = document.querySelector("#edit-submitted-plot-button");
        if (el) {
          let id = el.parentElement.id;
          el.addEventListener("click", (e: any) => {
            var feat = draw.get(id);
            if (feat.properties.isCircle) {
              drawingMode.current = "drag_circle";
              ctrl.draw.changeMode("direct_select", { featureId: id });
            } else ctrl.draw.changeMode("custom_select", { featureId: id });
          });
        }
      };
      deleteUnsubmittedObserverInstance.current = new MutationObserver(
        deleteUnsubmittedObserver
      );
      deleteUnsubmittedObserverInstance.current.observe(document.body, {
        subtree: true,
        childList: true,
      });

      deleteSubmittedObserverInstance.current = new MutationObserver(
        deleteSubmittedObserver
      );
      deleteSubmittedObserverInstance.current.observe(document.body, {
        subtree: true,
        childList: true,
      });

      submitPlotObserverInstance.current = new MutationObserver(submitObserver);
      submitPlotObserverInstance.current.observe(document.body, {
        subtree: true,
        childList: true,
      });

      editPlotObserverInstance.current = new MutationObserver(editObserver);
      editPlotObserverInstance.current.observe(document.body, {
        subtree: true,
        childList: true,
      });

      drawRectObserverInstance.current = new MutationObserver(drawRectObserver);
      drawRectObserverInstance.current.observe(document.body, {
        subtree: true,
        childList: true,
      });

      drawCircleObserverInstance.current = new MutationObserver(
        drawCircleObserver
      );
      drawCircleObserverInstance.current.observe(document.body, {
        subtree: true,
        childList: true,
      });

      drawPointObserverInstance.current = new MutationObserver(
        drawPointObserver
      );
      drawPointObserverInstance.current.observe(document.body, {
        subtree: true,
        childList: true,
      });

      ctrl.shapesContainer.className =
        "mapboxgl-ctrl-group mapboxgl-ctrl shapes-buttons-container";
      ctrl.shapesContainer.style.visibility = "visible";
      return ctrl.shapesContainer;
    }
    onRemove(map: any) {
      submitPlotObserverInstance.current.disconnect();
      deleteSubmittedObserverInstance.current.disconnect();
      editPlotObserverInstance.current.disconnect();
      deleteUnsubmittedObserverInstance.current.disconnect();
    }
  }
  const myCustomControl = new MyCustomControl({ draw: draw });
  return {
    handleWGSCoordinates,
    handleCoordinates,
    selectedSearchMethod,
    setSelectedSearchMethod,
    drawingMethod,
    acresUsed,
    setDrawingMethod,
    setEnableDrawing,
    mapRef,
    currentMapMode,
    setCurrentMapMode,
    firstSearchTime,
    layerLoading,
    setFirstSearchTime,
    isDropdownFocused,
    setIsDropdownFocused,
    handleSelectedSearchMethod,
    wgsCoordinates,
    coordinates
  };
};

export default useMap;
