import { FC } from 'react'
import ConfirmationMessageProps from './index.interface'
import { successIcon, failureIcon, closeSuccess, closeFailure } from 'assets/icons/svg/calendar/index'

const ConfirmationMessage: FC<ConfirmationMessageProps> = ({ type, handleButtonClick, messageTitle, messageSubtitle }) => {
    return (
        <div className={type === 'success' ?'message-container':'message-container failure-container'}>
            <div className={'message-icon'}>{type === 'success' ? successIcon : failureIcon}</div>
            <div className={'message-content'}>
                <button onClick={() => handleButtonClick(false)}>{type === 'success' ? closeSuccess : closeFailure}</button>
                <span className={type === 'success' ? 'message-title' : 'message-title failure-title'}>{messageTitle}</span>
                <span className={type === 'success' ? 'message-subtitle' : 'message-subtitle failure-subtitle'}>{messageSubtitle}</span>
            </div>
        </div>
    )
}
export default ConfirmationMessage