
import {FC, useState} from 'react'
import { note } from 'assets/icons/svg/ManualInput/index'
import Input from 'components/Input';
import { plotIcon, deleteIcon } from 'assets/icons/svg/FarmRegistration/index';
import Checkbox from 'components/Checkbox';
import { UnsubmittedPlotProps } from './index.interface';
import DropDownSelector from 'components/DropDownSelector';
import RadioButton from 'components/RadioButton';
import translateNumbers from 'utils/translateNumbers';
import SearchDropDownList from 'components/SearchDropDownList';
import { cropsIcon } from 'assets/icons/svg/FarmRegistration/index';
import { IOption } from 'components/SearchDropDownList/index.interface';
const UnsubmittedPlot: FC<UnsubmittedPlotProps> = ({ t, soilTypes, handleSelectedSoil, handlePlotDelete, handleSubmitPlot, handleSelectedCrop, cropsList, showMoreLanguages, handleShowMoreLanguagesToggle, plot, index, handlePlotTypeSelectionChange, handlePlotNameChange }) => {
    const locale = localStorage.getItem('i18nextLng')
    const tempIndex = cropsList?.findIndex((crop: IOption) => crop.value === plot?.properties.cropId) || 0;
    let disableSubmit = !plot.properties.nameEn || plot.properties.nameEn.length === 0 || (showMoreLanguages && locale === 'en' && !plot.properties.nameAr) || (showMoreLanguages && locale === 'ar' && !plot.properties.nameEn) || plot.properties.isValidArea === 'false'
    let defaulPlotName = locale === 'en' ? plot.properties.nameEn : plot.properties.nameAr;
    let secondaryPlotName= locale === 'en' ? plot.properties.nameAr : plot.properties.nameEn;
    const [plotSubmitted, setPlotSubmitted] = useState(false);
    return (
        <div className='unsubmitted-plot-wrapper' id={plot.id}>
            <div className='card-wrapper'>
                <div className='plot-card-title ' id={plot.id}>
                    <span>{t('plot')} #{translateNumbers((index + 1).toString())}</span>
                    <button
                        id={"delete-unsubmitted-plot-button"}
                        className="delete-button"
                        onClick={() => {
                            setPlotSubmitted(false);
                            handlePlotDelete(plot.id);
                        }}
                    >
                        <span className='icon'>{deleteIcon}</span>
                    </button>
                </div>
                <div className={`plot-card-body ${!plotSubmitted ? 'explanation-message-wrapper' :''}`}>
                    <Input inputType={'text'} title={t('plotName')} defaultValue={defaulPlotName}
                        handleChange={(e: any) => {setPlotSubmitted(false); handlePlotNameChange(plot.id, e.target.value, locale);}} 
                        maxLength={40} 
                        iconImage={plotIcon}
                        errorMessage={t('requiredValue')}
                        error={!defaulPlotName}
                        isMandatory
                    />
                    <div className={`another-languages-container ${!defaulPlotName ? 'dimmed-checkbox' : ''}`}>
                        <Checkbox checked={showMoreLanguages}
                            title={t('plotLangDefinition')}
                            action={() => handleShowMoreLanguagesToggle(plot.id, locale)} />
                        {(showMoreLanguages || (plot.properties.nameEn !== plot.properties.nameAr)) && <div className='language-options-view'>
                            <span className='note'>{note}{t('namingNote')}</span>
                            <div className='language-row'>
                                <DropDownSelector choicesList={[locale === 'en' ? 'العربية' : 'English']} currentChoice={locale === 'en' ? 'العربية' : 'English'} setCurrentChoice={(choice: string) => console.log('')} />
                                <Input inputType={'text'} title={t('plotName')} iconImage={plotIcon} defaultValue={plot.properties.isUpdating ? (locale === 'en' ? plot.properties.nameAr : plot.properties.nameEn) : ''}
                                    handleChange={(e: any) => handlePlotNameChange(plot.id, e.target.value, locale === 'en' ? 'ar' : 'en')} maxLength={40}
                                    error={!secondaryPlotName}
                                />
                                <button
                                    className="delete-button"
                                    onClick={() => {
                                        handleShowMoreLanguagesToggle(plot.id, locale)
                                    }}
                                >
                                    {deleteIcon}
                                </button>
                            </div>
                            {!secondaryPlotName && <div className={`input-error-message ${!plotSubmitted ? 'explanation-message' :''} custom-in-language-toggle`}>{t('requiredValue')}</div>}
                        </div>}

                    </div>
                    <div className='info-key'>
                        {t('calculatedDimension')}
                    </div>
                    <div className='info-value' style={{ color: plot.properties.isValidArea === 'false' ? '#FC6F78' : '#46AAA0' }}>
                        {plot.properties.totalAcreage} {t('acres')}
                    </div>
                    <div className='info-key'>
                        {t('farmType')}
                    </div>
                    <div className='info-value select-container'>
                        <RadioButton
                            options={[t('drip'), t('pivot')]}
                            handleSelection={(v: any) => { handlePlotTypeSelectionChange(plot.id, v) }}
                            currentSelection={plot.properties.plotType === 'pivot' ? 1 : 0}
                        />
                    </div>
                    <div className='info-key'>
                        {t('cropTypeTitle')}
                    </div>
                    <div className='info-value crop-select'>
                        <SearchDropDownList 
                            options={cropsList}
                            onChangeHandler={(option: IOption[]) => handleSelectedCrop(plot.id, option[0])}
                            valueIcon={cropsIcon}
                            selectedValue={cropsList[tempIndex].text}
                            valueWidth='10rem'
                        />
                    </div>
                    
                </div>
            </div>
            <button
                id="plot-submit-button"
                className="submit-button"
                // disabled={disableSubmit}
                disabled={false}
                onClick={disableSubmit ? () => {setPlotSubmitted(true); return ;} : () => {
                    handleSubmitPlot(plot.id)
                }}
            >
                {/* <span className='icon'>{confirm}</span> */}
                {t('definePlot')}
            </button>
        </div>
    )
}

export default UnsubmittedPlot
