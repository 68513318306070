import { contactUs } from "assets/icons/svg/ContactUs";
import { useTranslation } from 'react-i18next';
import useConactUs from "./useContactUs";
import NewTooltip from "components/NewTooltip";
import { IContactUs } from "./index.interface";
import { FC } from "react";

const ContactUs:FC<IContactUs> = ({position='end'}) => {
  const { t } = useTranslation();
  const { handleClick } = useConactUs();
  return (
    <>
      <button id='contact-us' className="contact-us" onClick={handleClick}>{contactUs}</button>
      <NewTooltip content={t('contactTooltip')} parent='#contact-us' position={position}/>
    </>
  )
};

export default ContactUs;
