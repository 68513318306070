import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "store";
import { ORGANIZATIONS } from "utils/statusCodes";
import useHandleErrorHook from "utils/useHandleErrorHook";
import { getOrgInfo } from './api'
const useOrganizationInfo = () => {
    const [organizationDetails, setOrganizationDetails] = useState();
    let selectedOrganization = useSelector((reduxState: ReduxState) => reduxState.selectedOrganization)
    const { handleErrorHelper } = useHandleErrorHook()

    const getOrganizationData = async function () {
        try {
            if (selectedOrganization?.id) {
                const response = await getOrgInfo(selectedOrganization.id);
                if (response?.data?.statusCode === ORGANIZATIONS.GET_ORGANIZATION_DETAILS_SUCCESS)
                    setOrganizationDetails(response.data.payload)
            }
        }
        catch (error) {
            handleErrorHelper(error)
        }
    }
    useEffect(() => {
        getOrganizationData();
    }, [])
    return { organizationDetails, selectedOrganization }
}


export default useOrganizationInfo;
