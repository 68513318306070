import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import * as turf from '@turf/turf'
import { getCrops, defineFarm } from "./api";
import useHandleErrorHook from "utils/useHandleErrorHook";
import { fromSquareMeterToAcres } from 'utils/unitConversions'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'store'
import { get } from 'services/ApiFetching';
import { DEFINE_FARM } from "utils/statusCodes";
import { purifyText } from "utils/textUtil";
import { IOption } from "components/SearchDropDownList/index.interface";
import { useIsLoggedIn } from "utils/SSOUtil";

const usePanel = (t: Function, selectedOrganization: any) => {
    const { state } = useLocation();
    const [currentFarmNameEn, setCurrentFarmNameEn] = useState(state?.name);
    const [currentFarmNameAr, setCurrentFarmNameAr] = useState(state?.name);
    const organizationId = selectedOrganization?.id;
    const [farmLocation, setFarmLocation] = useState<string>("");
    const [farmLocationAr, setFarmLocationAr] = useState<string>("");
    const [farmDescription, setFarmDescription] = useState<string>("");
    const [farmDescriptionAr, setFarmDescriptionAr] = useState<string>("");
    const [errorFields, setErrorFields] = useState<any>({
        farmLocation : t('requiredValue'),
        farmLocationAr : t('requiredValue'),
        currentFarmNameEn : '',
        currentFarmNameAr : '',
    })
    const locale = localStorage.getItem('i18nextLng') || 'en';
    const farmLocationErrorField = locale === 'ar' ? 'farmLocationAr' : 'farmLocation';
    const farmLocationSecondaryErrorField = locale === 'ar' ? 'farmLocation' : 'farmLocationAr';
    const farmDescriptionErrorField = locale === 'ar' ? !farmDescriptionAr :  !farmDescription ;
    const farmNameErrorField = `currentFarmName${locale[0].toLocaleUpperCase()}${locale[1]}` ;
    const farmNameSecondaryErrorField = farmNameErrorField === 'currentFarmNameEn' ? 'currentFarmNameAr' : 'currentFarmNameEn';
    const [plotsList, setPlotsList] = useState<any>([]);
    const [cropsList, setCropsList] = useState<any>([]);
    const [showMoreLanguages, setShowMoreLanguages] = useState(false);
    const { handleErrorHelper } = useHandleErrorHook()
    const [enableDrawing, setEnableDrawing] = useState(true);
    const [showMoreLanguagesFields, setShowMoreLanguagesFields] = useState<string[]>([]);
    const [defineClicked, setDefineClicked] = useState(false);
    const [responseMessage, setResponeMessage] = useState<any>();
    const [userSubmitted, setUserSubmitted] = useState(false);
    const navigate = useNavigate();
    const isLoggedIn  = useIsLoggedIn();
    const dispatch = useDispatch()
    const setPermissionsState = (state: any) => dispatch({ type: 'set', permissions: state })
    async function getAllPermissions() {
        const response = await get(`/auth/permissions`);
        return response;
    };
    const soilTypes = [
        "sandy soil",
        "loamy sand soil",
        "sandy loam soil",
        "medium loam soil",
        "silty loam soil",
        "silty soil",
        "sandy clay loam soil",
        "clay loam soil",
        "silty clay loam soil",
        "sandy clay soil",
        "clay soil",
        "silty clay soil"
    ];
    const [selectedSoilType, setSelectedSoilType] = useState<any>("sandy soil");
    
    
    function handleSelectedSoil(id: string, choice: string) {
        if (id) {
            let plotIndex = plotsList.findIndex((p: any) => p.id === id);
            let plot = plotsList[plotIndex]
            let currPlots = [...plotsList]
            plot.properties.soilType = choice
            currPlots[plotIndex] = plot
            setPlotsList(currPlots)
        }
        else {
            setSelectedSoilType(choice)
        }
    }
    function sendToModalFunction(farmName: string) {
        const locale = localStorage.getItem('i18nextLng')
        if (locale === 'en')
            setCurrentFarmNameEn(purifyText(farmName))
        else
            setCurrentFarmNameAr(purifyText(farmName))
    }

    function handleFarmNameChange(name: string) {
        const locale = localStorage.getItem('i18nextLng');
        const tempName = purifyText(name);
        if (locale === 'ar'){
            setCurrentFarmNameEn(tempName);
            setErrorFields((prev:any) =>  {return {...prev, 
                currentFarmNameEn: tempName !=='' ? '' : t('requiredValue'),
            }});
        }
        else{
            setCurrentFarmNameAr(tempName)
            setErrorFields((prev:any) =>  {return {...prev, 
                currentFarmNameAr: tempName !=='' ? '' : t('requiredValue'),
            }})
        }
    }

    function handleFarmLocationChange(location: string, lang: string) {
        const tempLocation = purifyText(location);
        setUserSubmitted(false);

        if (lang === 'en'){
            setFarmLocation(tempLocation);
            setErrorFields((prev:any) =>  {return {...prev, farmLocation: tempLocation !== '' ? '' : t('requiredValue')}})
        }
        else {
            if (!lang) {
                setFarmLocation(tempLocation);
                setFarmLocationAr(tempLocation);
                setErrorFields((prev:any) =>  {return {...prev, 
                    farmLocationAr: tempLocation !=='' ? '' : t('requiredValue'),
                    farmLocation: tempLocation !=='' ? '' : t('requiredValue')
                }})

            }
            else{
                setFarmLocationAr(tempLocation)
                setErrorFields((prev:any) =>  {return {...prev, farmLocationAr: tempLocation !=='' ? '' : t('requiredValue')}})
            }
        }
    }

    function handleFarmDescriptionChange(description: string, lang: string) {
        if (lang === 'en')
            setFarmDescription(purifyText(description))
        else {
            if (!lang) {
                setFarmDescription(purifyText(description))
                setFarmDescriptionAr(purifyText(description))
            }
            else
                setFarmDescriptionAr(purifyText(description))
        }
    }

    function updatePlot(plots: any, updatedObj: any) {
        let currentPlots = [...plots]
        let plotIndex = currentPlots.findIndex((p: any) => p.id == updatedObj.id);
        let plot = { ...updatedObj }
        plot.properties = { ...currentPlots[plotIndex].properties }
        var polygon = turf.polygon(updatedObj.geometry.coordinates);
        var centroid: any = turf.centroid(polygon);
        plot.properties.centerX = centroid.geometry.coordinates[0]
        plot.properties.centerY = centroid.geometry.coordinates[1]
        let area = turf.area(updatedObj)
        area = fromSquareMeterToAcres(area)
        plot.properties.totalAcreage = area.toFixed(2)
        plot.properties.isValidArea = updatedObj.properties.isValidArea;

        plot.properties.center = updatedObj.properties.center;
        plot.properties.radiusInKm = updatedObj.properties.radiusInKm;

        currentPlots[plotIndex] = plot
        setPlotsList(currentPlots)
    }
    function addNewPlot(newObj: any) {
        var polygon = turf.polygon(newObj.geometry.coordinates);
        var centroid: any = turf.centroid(polygon);
        newObj.properties.centerX = centroid.geometry.coordinates[0]
        newObj.properties.centerY = centroid.geometry.coordinates[1]
        let area = turf.area(newObj)
        area = fromSquareMeterToAcres(area)
        newObj.properties.totalAcreage = area.toFixed(2)
        newObj.properties.cropId = cropsList[0].value
        newObj.properties.soilType = soilTypes[0]
        newObj.properties.plotType = "drip"
        setShowMoreLanguages(false)
        setPlotsList((plots: any) => [...plots, newObj])
        let objDiv: any = document.getElementById("panel-content-wrapper");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    function handlePlotNameChange(id: string, value: string, lang: string) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]

        if (plot) {
            if (lang === 'ar') {
                plot.properties.nameAr = purifyText(value)
                if (!showMoreLanguages)
                plot.properties.nameEn = purifyText(value)
            }
            else {
                plot.properties.nameEn = purifyText(value)
                if (!showMoreLanguages)
                    plot.properties.nameAr = purifyText(value)
            }
            currPlots[plotIndex] = plot
            setPlotsList(currPlots)
        }
    }

    function handlePlotTypeSelectionChange(id: string, value: number) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]
        if (value === 0)
            plot.properties.plotType = "drip"
        else
            plot.properties.plotType = "pivot"
        currPlots[plotIndex] = plot
        setPlotsList(currPlots)
    }

    function handlePlotDelete(id: string) {
        let currPlots = [...plotsList]
        currPlots = currPlots.filter((plot: any) => plot.id !== id)
        setShowMoreLanguages(false)
        setPlotsList(currPlots)
    }
    function handleSubmitPlot(id: string) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]
        plot.properties.status = "static"
        plot.properties.isUpdating = false
        currPlots[plotIndex] = plot
        setPlotsList(currPlots)
        setEnableDrawing(true)
        setShowMoreLanguages(false)
    }

    async function handleDefineFarm() {
        setUserSubmitted(true);
        if(errorFields[farmLocationErrorField]){
            setErrorFields((prev:any) =>  {return {...prev, [farmLocationErrorField]: t('enterRequiredFields')}})
            return ;
        }
        if(showMoreLanguagesFields.includes('farmLocation') && errorFields[farmLocationSecondaryErrorField]){
            setErrorFields((prev:any) =>  {return {...prev, [farmLocationSecondaryErrorField]: t('enterRequiredFields')}})
            return ;
        }
        if(showMoreLanguagesFields.includes('farmName') && errorFields[farmNameSecondaryErrorField]){
            setErrorFields((prev:any) =>  {return {...prev, [farmNameSecondaryErrorField]: t('enterRequiredFields')}})
            return ;
        }
        if (defineClicked || plotsList.length === 0 || plotsList.some((plot: any) => plot.properties.status === 'active') || !farmLocation || (!currentFarmNameEn && !currentFarmNameAr) ){
            return ;
        }
        setDefineClicked(true)
        let map: any = {
            type: "FeatureCollection",
            features: plotsList.map((plot: any) => ({
                ...plot,
                id: null,
            }))
        }
        var centroid: any = turf.centroid(map);
        let area = turf.area(map)
        area = fromSquareMeterToAcres(area)
        let requestBody = {
            organizationId: organizationId,
            nameEn: !showMoreLanguagesFields.includes('farmName') && locale ==="ar"?currentFarmNameAr: currentFarmNameEn,
            nameAr:!showMoreLanguagesFields.includes('farmName') && locale ==="en"? currentFarmNameEn:currentFarmNameAr,
            descriptionEn: farmDescription,
            descriptionAr: farmDescriptionAr,
            addressEn: farmLocation,
            addressAr: farmLocationAr,
            totalAcreage: area.toFixed(2),
            longitude: centroid.geometry.coordinates[0].toString(),
            latitude: centroid.geometry.coordinates[1].toString(),
            map: map
        }
        const response = await defineFarm(requestBody);
        if (response?.data?.statusCode === DEFINE_FARM.DEFINE_FARM_SUCESS) {
            setResponeMessage({
                type: "success",
                messageTitle: t('success'),
                messageSubtitle: t('farmAddSuccess')
            })

            if (isLoggedIn) {
                getAllPermissions().then(result => {
                    if (result.data.statusCode === 200) {
                        setPermissionsState(result.data.payload)
                    }
                })
            }
            setTimeout(() => {
                setResponeMessage(undefined);
                navigate('/UserFarms', { state: { scrollToEnd: true, farmId: response?.data.payload } })
                // window.location.reload();
            }, 1500)
        }
        else
            if (response.data.statusCode === DEFINE_FARM.ORGANIZATION_CAPACITY_EXCEEDED) {
                setResponeMessage({
                    type: "failed",
                    messageTitle: t('calendarFailedProcess'),
                    messageSubtitle: t('farmLimitError')
                })
                setTimeout(() => {
                    setResponeMessage(undefined);
                }, 1500);
            }
            else
                if (response.data.statusCode === DEFINE_FARM.DUPLICATE_FARM_EXIST) {
                    setResponeMessage({
                        type: "failed",
                        messageTitle: t('calendarFailedProcess'),
                        messageSubtitle: t('duplicateFarmError')
                    })
                    setTimeout(() => {
                        setResponeMessage(undefined);
                    }, 1500);
                }
                else {
                    setResponeMessage({
                        type: "failed",
                        messageTitle: t('calendarFailedProcess'),
                        messageSubtitle: t('farmAddFailure')
                    })
                    setTimeout(() => {
                        setResponeMessage(undefined);
                    }, 1500);
                }

        setDefineClicked(false)
    }

    function handleShowMoreLanguagesToggle(field: string) {
        const locale = localStorage.getItem('i18nextLng')
        let currentFields: string[] = [...showMoreLanguagesFields]
        if (currentFields.includes(field)) {
            currentFields = currentFields.filter((f: string) => f !== field);
            switch (field) {
                case 'farmName': { if (locale === 'en') setCurrentFarmNameAr(currentFarmNameEn); else setCurrentFarmNameEn(currentFarmNameAr) }; break;
                case 'farmDescription': { if (locale === 'en') setFarmDescriptionAr(farmDescription); else setFarmDescription(farmDescriptionAr) }; break;
                case 'farmLocation': { 
                        if (locale === 'en') { 
                            setFarmLocationAr(farmLocation); 
                            setErrorFields((prev:any) =>  {return {...prev, 
                                farmLocationAr: farmLocation !=='' ? '' : t('requiredValue')
                            }})
                        }
                        else {
                            setFarmLocation(farmLocationAr) 
                            setErrorFields((prev:any) =>  {return {...prev, 
                                farmLocation: farmLocationAr !=='' ? '' : t('requiredValue')
                            }})
                        }
                    }; 
                break;
            }
        }
        else
            currentFields.push(field)
        setShowMoreLanguagesFields(currentFields);
    }

    function handlePlotShowMoreLanguagesToggle(id: string, lang: string) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]

        if (!showMoreLanguages) {
            setShowMoreLanguages(true)
        }
        else {
            if (lang === 'ar')
                plot.properties.nameEn = plot.properties.nameAr
            else
                plot.properties.nameAr = plot.properties.nameEn
            setShowMoreLanguages(false)
            currPlots[plotIndex] = plot
            setPlotsList(currPlots)
        }
    }

    function handleSelectedCrop(id: string, cropSelected: IOption) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]
        let cropId = cropSelected.value;
        plot.properties.cropId = cropId
        currPlots[plotIndex] = plot
        setPlotsList(currPlots)
    }

    function handlePlotEdit(id: string) {
        setEnableDrawing(false)
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]
        plot.properties.status = 'active'
        plot.properties.isUpdating = true
        currPlots[plotIndex] = plot
        setPlotsList(currPlots)
        if (plot.properties.nameEn !== plot.properties.nameAr)
            setShowMoreLanguages(true)
    }

    function disableDefineFarm() {
        // if (defineClicked || plotsList.length === 0 || !farmLocation || !farmDescription || (!currentFarmNameEn && !currentFarmNameAr) || plotsList.some((plot: any) => plot.properties.status === 'active'))
        if (defineClicked)
            return true
        return false
    }
    const getAllCrops = async () => {
        try {
            const allCropsResponse: any = await getCrops();
            if (allCropsResponse?.data?.statusCode === 200) {
                const formattedCrops = allCropsResponse.data.payload.map((crop: any) => {
                    return {
                        value: crop.id,
                        text: crop.name,
                        selected: false
                    }
                });
                setCropsList(formattedCrops)
            }
        }
        catch (error) {
            handleErrorHelper(error)
        }
    }
    useEffect(() => {
        getAllCrops();
        //eslint-disable-next-line
    }, [])
    return {
        currentFarmNameEn, showMoreLanguages, cropsList, handleSelectedCrop, handleShowMoreLanguagesToggle, sendToModalFunction, handlePlotShowMoreLanguagesToggle,
        handlePlotTypeSelectionChange, plotsList, farmDescription, handleFarmDescriptionChange, disableDefineFarm, handlePlotDelete, showMoreLanguagesFields,
        handlePlotNameChange, handleFarmLocationChange, addNewPlot, handleSubmitPlot, handleDefineFarm, enableDrawing, setEnableDrawing, handleFarmNameChange,
        selectedSoilType, soilTypes, handleSelectedSoil, handlePlotEdit, updatePlot, responseMessage, setResponeMessage, currentFarmNameAr,
        errorFields, farmLocationErrorField, farmDescriptionErrorField, farmLocationSecondaryErrorField, userSubmitted, farmNameSecondaryErrorField

    };
}


export default usePanel;
