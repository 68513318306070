
import { editIcon } from 'assets/icons/svg/constants';
import { deleteIcon } from 'assets/icons/svg/FarmRegistration';
import { FC } from 'react'
import translateNumbers from 'utils/translateNumbers';
import { SubmittedPlotProps } from './index.interface'

const SubmittedPlot: FC<SubmittedPlotProps> = ({ t, enableActions, plot, index, handlePlotEdit, cropsList, handlePlotDelete }) => {

    let plotName = plot?.properties?.nameEn === plot?.properties?.nameAr ? plot?.properties?.nameEn : `${plot?.properties?.nameEn},${plot?.properties?.nameAr}`
    let cropName = cropsList.find((crop: any) => crop.value === plot?.properties?.cropId)?.text
    return (
        <div className='submitted-card'>
            <div className='plot-id'>{t('plot')} #{translateNumbers((index + 1).toString())}</div>
            <div className='info-row'>
                <span className='key'>{t('name')}: </span>
                <span className='value'>{plotName}</span>
            </div>
            <div className='info-row'>
                <span className='key'>{t('dimension')}: </span>
                <span className='value'>{plot?.properties?.totalAcreage} {t('acres')}</span>
            </div>
            <div className='info-row'>
                <span className='key'>{t('farmType')}: </span>
                <span className='value'>{t(plot?.properties?.plotType)}</span>
            </div>
            <div className='info-row'>
                <span className='key'>{t('cropTypeTitle')}: </span>
                <span className='value'>{cropName}</span>
            </div>
            <div className='info-row'>
                <span className='key'>{t('soilType')}: </span>
                <span className='value'>{plot?.properties?.soilType}</span>
            </div>
            <div className='buttons-container' id={plot.id}>
                <button
                    id={"edit-submitted-plot-button"}
                    disabled={!enableActions}
                    className="edit-button"
                    onClick={() => {
                        handlePlotEdit(plot.id)
                    }}
                >
                    {editIcon}
                </button>
                <button
                    id={"delete-submitted-plot-button"}
                    disabled={!enableActions}
                    className="delete-button delete-submitted-plot-button"
                    onClick={() => {
                        handlePlotDelete(plot.id)
                    }}
                >
                    {deleteIcon}
                </button>
            </div>
        </div>
    )
}

export default SubmittedPlot
