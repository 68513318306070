import { FC, useEffect, useState } from "react";
import { isMobile } from "services/helperFunctions";
import PullToRefresh from "./partials/PullToRefresh";

const RefreshComponent: FC<any> = ({ pullRef }) => {
  const [refreshMode, setRefreshMode] = useState<string>("");

  useEffect(() => {
    let match = window.matchMedia("(display-mode: standalone)").matches;
    if (match) {
      setRefreshMode("pwa-mobile");
    }
  }, []);

  if (refreshMode === "pwa-mobile") return <PullToRefresh pullRef={pullRef} />;
  else return <></>;
};

export default RefreshComponent;
