import { getEnv } from "env";
import { useSelector } from "react-redux";
import { ReduxState } from "store";

export const getCookie = (name: string) => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];
};

export const useIsLoggedIn = () => {
  let isLogged = useSelector((reduxState: ReduxState) => reduxState.isLogged);
  if (getEnv().NAMAA_ENV === "DEV") {
    return isLogged && localStorage.getItem("isLoggedIn") === "true";
  }
  return getCookie(getEnv().COOKIE_LOGIN_PROPERTY) ? true : false;
};
// export const isLoggedIn = useIsLoggedIn();

const getLink = () => {
  const currentPath = window.location.href;
  return `${getEnv().NAMAA_LOGIN_URL}?redirect_to=${encodeURIComponent(
    new URL(currentPath).origin
  )}`;
};
export const loginLink = getLink();

export const getEmail = () => {
  return JSON.parse(localStorage.getItem("userDetails")!)?.email;
};

const getLogoutLink = () => {
  const currentPath = window.location.href;
  return `${getEnv().NAMAA_LOGOUT}&redirect_to=${encodeURIComponent(
    new URL(currentPath).origin
  )}`;
};

export const logoutLink = getLogoutLink();
