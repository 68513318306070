import React from 'react'

interface Props {
  children?: any,
  hasFullHeight?: boolean
}

const Loader = ({ children, hasFullHeight=true }: Props) => {
  return (
    <div className={`Loading ${hasFullHeight ? 'Loading-height' : ''}`}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      {children}
    </div>

  )
}

export default Loader