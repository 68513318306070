const translateNumbers = (number: string|number) => {
    if(typeof number === 'number') number = number.toString();
    const lang = localStorage.getItem('i18nextLng') || 'en';
    const arabicNums = ['٠','١','٢','٣','٤','٥','٦','٧','٨','٩'];
    if(lang==="ar"){
        return number.replace(/[0-9.]/g, function(n){
            if (n === ".") 
                return ",";
            return arabicNums[+n];
        });
    }
    return number;
}
export default translateNumbers;
