import Input from 'components/Input';
import ThePanelView from 'containers/ThePanelView'
import { FC } from 'react'
import { inputPin, descriptionInput } from 'assets/icons/svg/FarmRegistration/index';
import { editIcon } from "assets/icons/svg/constants";
import UnsubmittedPlot from './partials/UnsubmittedPlot';
import { PanelViewProps } from './index.interface';
import SubmittedPlot from './partials/SubmittedPlot';
import ProceedButton from 'components/ProceedButton';
import translateNumbers from 'utils/translateNumbers';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store';
import Checkbox from 'components/Checkbox';
import { deleteIcon } from 'assets/icons/svg/FarmRegistration/index';
import DropDownSelector from 'components/DropDownSelector';
import { exclamationRound } from 'assets/icons/svg/index';
const PanelView: FC<PanelViewProps> = ({ t, enableDrawing, showMoreLanguagesFields, soilTypes, handleSelectedSoil, handlePlotDelete, setShowModal,
    disableDefineFarm, handleDefineFarm, cropsList, handleSubmitPlot, handleSelectedCrop, showMoreLanguages, handleFarmNameChange,
    handleShowMoreLanguagesToggle, handlePlotTypeSelectionChange, plotsList, handlePlotEdit, handlePlotShowMoreLanguagesToggle,
    handlePlotNameChange, setShowCancelModal, farmDescription, farmNameEn, farmNameAr, handleFarmDescriptionChange, handleFarmLocationChange, errorFields,
    farmDescriptionErrorField, farmLocationErrorField, farmLocationSecondaryErrorField, userSubmitted, farmNameSecondaryErrorField
}) => {

    let selectedOrganization = useSelector((reduxState: ReduxState) => reduxState.selectedOrganization)
    const locale = localStorage.getItem('i18nextLng');
    return (
        <div className='farm-registration-panel-container'>
            <ThePanelView title={t('farmConfiguration')} closeAsideBar={() => setShowCancelModal(true)} toggle={true}>
                <div className='panel-subtitle'>{t('farmRegistPanelSub')}</div>
                <div className='panel-content-wrapper' id={'panel-content-wrapper'}>

                    <div className='basic-info-wrapper'>
                        <div className='info-key'>
                            {t('farmOrganization')}
                        </div>
                        <div className='info-value'>
                            {locale === 'en' ? selectedOrganization.nameEn : selectedOrganization.nameAr}
                        </div>
                        <div className='info-key'>
                            {t('farmName')}
                        </div>

                        <div className='info-value'>
                            {locale === 'en' ? farmNameEn : farmNameAr}
                            <button
                                className="edit-button"
                                onClick={() => {
                                    setShowModal(true)
                                }}
                            >
                                {editIcon}
                            </button>
                            <div className='another-languages-container'>
                                <Checkbox checked={showMoreLanguages}
                                    title={t('plotLangDefinition')}
                                    action={() => handleShowMoreLanguagesToggle('farmName')} />
                                {showMoreLanguagesFields.includes('farmName') && <div className='language-options-view'>
                                    {/* <span className='note'>{note}{t('namingNote')}</span> */}
                                    <div className='language-row'>
                                        <DropDownSelector choicesList={[locale === 'en' ? 'العربية' : 'English']} currentChoice={locale === 'en' ? 'العربية' : 'English'} setCurrentChoice={(choice: string) => console.log('')} />
                                        <Input inputType={'text'} title={t('farmName')} inputFocusPaddingInlineStart={0}
                                            handleChange={(e: any) => handleFarmNameChange(e.target.value)} maxLength={40}
                                            error={errorFields[farmNameSecondaryErrorField]}
                                            errorMessage={errorFields[farmNameSecondaryErrorField]}
                                            showExplanationMessage={!userSubmitted}
                                            isMandatory
                                        />
                                        <button
                                            className="delete-button"
                                            onClick={() => {
                                                handleShowMoreLanguagesToggle('farmName')
                                            }}
                                        >
                                            {deleteIcon}
                                        </button>
                                    </div>
                                </div>}

                            </div>
                        </div>
                        {/* <div className='line-seperator' /> */}
                        <div className='field-gap' />

                        {/* <div className='info-key'>
                            {t('farmLocation')}
                        </div> */}
                        <div className='location-input'>
                            <Input inputType='text' title={t('locationName')}
                                handleChange={(e: any) => handleFarmLocationChange(e.target.value)} maxLength={40} iconImage={inputPin}
                                errorMessage={errorFields[farmLocationErrorField]}
                                error={errorFields[farmLocationErrorField]}
                                isMandatory
                                showExplanationMessage={!userSubmitted}
                            />
                            <div className={`another-languages-container ${(errorFields[farmLocationErrorField]) ? 'dimmed-checkbox' : ''}`}>
                                <Checkbox checked={showMoreLanguages}
                                    title={t('plotLangDefinition')}
                                    action={() => handleShowMoreLanguagesToggle('farmLocation')} />
                                {showMoreLanguagesFields.includes('farmLocation') && <div className='language-options-view'>
                                    {/* <span className='note'>{note}{t('namingNote')}</span> */}
                                    <div className='language-row'>
                                        <DropDownSelector choicesList={[locale === 'en' ? 'العربية' : 'English']} currentChoice={locale === 'en' ? 'العربية' : 'English'} setCurrentChoice={(choice: string) => console.log('')} />
                                        <Input inputType='text' title={t('locationName')} inputFocusPaddingInlineStart={0}
                                            handleChange={(e: any) => handleFarmLocationChange(e.target.value, locale === 'en' ? 'ar' : 'en')} maxLength={40}
                                            error={errorFields[farmLocationSecondaryErrorField]}
                                            errorMessage={errorFields[farmLocationSecondaryErrorField]}
                                            showExplanationMessage={!userSubmitted}
                                            isMandatory
                                        />
                                        <button
                                            className="delete-button"
                                            onClick={() => {
                                                handleShowMoreLanguagesToggle('farmLocation')
                                            }}
                                        >
                                            {deleteIcon}
                                        </button>
                                    </div>
                                </div>}

                            </div>
                        </div>
                        {/* <div className='info-key'>
                            {t('farmDescription')}
                        </div> */}
                        <div className='description-input'>
                            <Input inputType={'text'} title={t('farmDescription')} isTextArea={true}
                                handleChange={(e: any) => handleFarmDescriptionChange(e.target.value)} maxLength={40} iconImage={descriptionInput}
                            />
                            {farmDescription && <div className='description-length'>{farmDescription?.length}/{translateNumbers("40")}</div>}
                            <div className={`another-languages-container ${(farmDescriptionErrorField) ? 'dimmed-checkbox' : ''}`}>
                                <Checkbox checked={showMoreLanguages}
                                    title={t('plotLangDefinition')}
                                    action={() => handleShowMoreLanguagesToggle('farmDescription')} />
                                {showMoreLanguagesFields.includes('farmDescription') && <div className='language-options-view'>
                                    {/* <span className='note'>{note}{t('namingNote')}</span> */}
                                    <div className='language-row'>
                                        <DropDownSelector choicesList={[locale === 'en' ? 'العربية' : 'English']} currentChoice={locale === 'en' ? 'العربية' : 'English'} setCurrentChoice={(choice: string) => console.log('')} />
                                        <Input inputType={'text'} title={t('farmDescription')} inputFocusPaddingInlineStart={0}
                                            handleChange={(e: any) => handleFarmDescriptionChange(e.target.value, locale === 'en' ? 'ar' : 'en')} maxLength={40}
                                        />
                                        <button
                                            className="delete-button"
                                            onClick={() => {
                                                handleShowMoreLanguagesToggle('farmDescription')
                                            }}
                                        >
                                            {deleteIcon}
                                        </button>
                                    </div>
                                </div>}

                            </div>
                        </div>
                    </div>
                    <div className='plot-list-wrapper'>
                        {plotsList?.map((plot: any, index: number) => {
                            return plot.properties.status === 'active' ?
                                <UnsubmittedPlot key={plot.id} handlePlotTypeSelectionChange={handlePlotTypeSelectionChange} soilTypes={soilTypes} handleSelectedSoil={handleSelectedSoil}
                                    handlePlotNameChange={handlePlotNameChange} index={index} t={t} plot={plot} handlePlotDelete={handlePlotDelete}
                                    showMoreLanguages={showMoreLanguages} handleShowMoreLanguagesToggle={handlePlotShowMoreLanguagesToggle}
                                    cropsList={cropsList} handleSelectedCrop={handleSelectedCrop} handleSubmitPlot={handleSubmitPlot} />
                                : <SubmittedPlot enableActions={enableDrawing} handlePlotEdit={handlePlotEdit} handlePlotDelete={handlePlotDelete} cropsList={cropsList} index={index} key={plot.id} t={t} plot={plot} />
                        })}
                    </div>
                    {(plotsList.length === 0 && userSubmitted && !errorFields[farmLocationErrorField]) && 
                        <div className='plots-error-message'>
                            <div className="plots-error-message__icon">
                                {exclamationRound}
                            </div>
                            <div className="plots-error-message__content">
                                <h4 className="plots-error-message__content__header">{t('plotErrorTitle')}</h4>
                                <p className="plots-error-message__content__subheader">{t('plotErrorSubtitle')}</p>
                            </div>
                        </div>
                    }
                </div>
                {!plotsList?.some((plot: any) => plot.properties.status === 'active') && 
                    <ProceedButton disabled={disableDefineFarm()} handleClick={handleDefineFarm} title={t('createFarm')} />
                }
            </ThePanelView >
        </div >
    )
}

export default PanelView
