import { FC } from "react";
import { INewTooltip } from "./index.interface";
import useNewTooltip from "./hooks/useNewTooltip";

const NewTooltip:FC<INewTooltip> = ({content, parent, position='end', className=''}) => {
    const { tooltipStyle, showTooltip } = useNewTooltip(parent, position);
 
    return (
        <div className={`${className} new-tooltip__wrapper ${showTooltip ? 'op-1' : 'op-0'} `} style={tooltipStyle}>
            <div className="new-tooltip__wrapper__content">
                <div className={`new-tooltip__wrapper__content__triangle ${position}`}></div>
                <div className="new-tooltip__wrapper__content__text">{content}</div>
            </div>
        </div>  
    );
}
 
export default NewTooltip;