import { FC, useEffect, useMemo, useState } from "react";
import {
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CTextarea,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
interface Props {
  name?: any;
  title: string;
  handleChange: any;
  icon?: any;
  autoComplete?: any;
  inputType: any;
  iconImage?: any;
  maxLength?: any;
  inputFocusPaddingInlineStart?: any;
  focus?: any;
  isTextArea?: boolean;
  prefix?: boolean;
  defaultValue?: any;
  error?: any;
  disabled?: boolean;
  screen?: string;
  keyValue?: any;
  value?: any;
  handleFocus?: Function | any;
  handleBlur?: Function | any;
  textLength?: "short" | "medium" | "large";
  autoFocus?: boolean;
  id?: string;
  showLength?: boolean;
  suffix?: string;
  errorMessage?: string;
  isMandatory?: boolean;
  showExplanationMessage?: boolean;
  onPaste?: Function;
  extraDataAttribute?: string | boolean;
  counter?:boolean;
  wordLength?:number;
  internalErrorInput?:boolean
}

const Input: FC<Props> = ({
  id,
  handleBlur,
  screen,
  autoFocus,
  title,
  handleChange,
  autoComplete,
  icon,
  inputType,
  iconImage,
  maxLength,
  defaultValue,
  inputFocusPaddingInlineStart = "",
  focus = false,
  isTextArea,
  prefix = false,
  error = false,
  disabled = false,
  value,
  handleFocus,
  textLength,
  errorMessage = "",
  showLength = false,
  suffix = "",
  isMandatory = false,
  showExplanationMessage = false,
  onPaste = undefined,
  extraDataAttribute = "",
  name,
  counter,
  wordLength,
  internalErrorInput = false
}) => {
  const [inputFocus, setInputFocus] = useState(focus);
  const [errorInput, setErrorInput] = useState(error);
  const [currentDefaultValue, setCurrentDefaultValue] = useState(defaultValue);
  const textmaxLength = {
    short: 10,
    medium: 40,
    large: 200,
  };
  useEffect(() => {
    setErrorInput(error);
    setCurrentDefaultValue(defaultValue);
  }, [error, defaultValue]);
  let classNames = isTextArea
    ? inputFocus || (document.getElementById("text-area") as any)?.value
      ? "mb-4 text-area focus"
      : "mb-4 text-area"
    : "mb-4";

  let borderErrorColor = internalErrorInput?(error && errorMessage && (inputFocus || isMandatory)):errorInput;
  const renderInnerInput = useMemo(() => {
    return (<>
      <CInputGroup
        className={`${classNames} ${icon || iconImage  ? 'input-with-icon' : ''}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {isTextArea ? (
          <CTextarea
            id="text-area"
            defaultValue={currentDefaultValue}
            style={{
              resize: "none",
              borderBottom:
                errorInput === true
                  ? "2px solid #F48585"
                  : "2px solid #C4FFF92E",
            }}
            onBlur={() => setInputFocus(false)}
            onFocus={() => setInputFocus(true)}
            placeholder={title}
            value={value}
            onChange={handleChange}
            type={inputType}
            autoComplete={autoComplete}
            onPaste={onPaste ? (e) => onPaste(e) : undefined}
            maxLength={
              textmaxLength[textLength!]
                ? textmaxLength[textLength!]
                : maxLength
            }
            data-custom={extraDataAttribute}
          />
        ) : (
          <CInput
            autoFocus={autoFocus}
            id={id}
            style={
              prefix
                ? { textIndent: "45px" }
                : borderErrorColor
                  ? { borderBottomColor: "#F48585" }
                  : {}
            }
            onBlur={() => setInputFocus(false)}
            defaultValue={currentDefaultValue}
            onFocus={() => setInputFocus(true)}
            placeholder="uuuuuuuuu"
            onChange={handleChange}
            type={inputType}
            autoComplete={autoComplete}
            maxLength={
              textmaxLength[textLength!]
                ? textmaxLength[textLength!]
                : maxLength
            }
            disabled={disabled}
            value={value}
            onPaste={onPaste ? (e) => onPaste(e) : undefined}
            data-custom={extraDataAttribute}
            name={name}
          />
        )}
        <span
          has-icon={icon || iconImage ? 'true' : 'false'}
          className={`${icon || iconImage ? 'has-icon' :'' } ${(isTextArea && inputFocus) || (isTextArea && defaultValue)
            ? "padding-bottom-20"
            : ""
            } 
        ${inputFocus ? "focus-color" : "default-color"}
        ${!icon && !iconImage && !inputFocus
              ? "default-input-focus-padding-inline"
              : ""
            }`}
          style={{
            paddingInlineStart: inputFocusPaddingInlineStart
              ? inputFocusPaddingInlineStart
              : "",
            color: errorInput ? "#F48585" : "#73E4CC"
          }}
        >
          {title}
        </span>
        {(icon || iconImage) && (
          <CInputGroupPrepend>
            <CInputGroupText>
              {icon !== undefined ? <CIcon name={icon} /> : 
              isTextArea?<div style={{paddingTop:"1.2em"}}>{iconImage}</div>:iconImage}
            </CInputGroupText>
          </CInputGroupPrepend>
        )}
        {suffix && <div className={error?"input-error-message input-suffix":"input-suffix"} dangerouslySetInnerHTML={{__html: suffix}} />}
        {counter && value && <span className={error?"input-error-message input-counter":"input-counter"}>{value.length}/{wordLength}</span>}
      </CInputGroup>
      </>);
  }, [currentDefaultValue, errorInput, inputFocus, screen, value]);
  return (
    <CForm
      onSubmit={(e) => e.preventDefault()}
      className={showExplanationMessage ? "explanation-message" : ""}
    >
      {screen === "calendar-component" ? (
        <div key={currentDefaultValue}>{renderInnerInput}</div>
      ) : 
        <>{renderInnerInput} 
        </>
      }
      {error && errorMessage && (inputFocus || isMandatory) ? (
        <div className="input-error-message">{errorMessage}</div>
      ) : null}
    </CForm>
  );
};
export default Input;
