import React from 'react'


export const meterTypeIcon = <svg id="Group_4893" data-name="Group 4893" xmlns="http://www.w3.org/2000/svg" width="19.435" height="17.491" viewBox="0 0 19.435 17.491">
  <g id="Layer_2" data-name="Layer 2">
    <path id="Path_3881" data-name="Path 3881" d="M11.717,3A9.717,9.717,0,0,0,2,12.717a9.611,9.611,0,0,0,3.634,7.56l.253.214H17.548l.262-.214a9.611,9.611,0,0,0,3.625-7.56A9.717,9.717,0,0,0,11.717,3Zm6.132,4.956L16.576,9.277,15.157,7.859l1.322-1.273A7.036,7.036,0,0,1,17.849,7.956ZM11.717,4.943A8.017,8.017,0,0,1,12.689,5V6.887H10.746V5a8.017,8.017,0,0,1,.972-.058ZM8.277,7.859,6.859,9.277,5.586,7.956a7.035,7.035,0,0,1,1.37-1.37ZM3.943,12.717A8.036,8.036,0,0,1,4,11.746H5.887v1.943H4.012a6.443,6.443,0,0,1-.068-.972Zm9.455,5.83a1.943,1.943,0,0,0-3.362,0H7.957a4.13,4.13,0,0,1,.146-.457,3.887,3.887,0,0,1,2.643-2.342L12.689,9.8h0v5.957a3.887,3.887,0,0,1,2.585,2.186,3.382,3.382,0,0,1,.2.6Zm4.149-4.859V11.746h1.885a8.036,8.036,0,0,1,.058.972,6.443,6.443,0,0,1-.068.972Z" transform="translate(-2 -3)" fill="#c4fff9" />
  </g>
  <rect id="Rectangle_4021" data-name="Rectangle 4021" width="7.544" height="3" transform="translate(5.891 13.389)" fill="#c4fff9" />
</svg>

export const range = <svg xmlns="http://www.w3.org/2000/svg" width="19.449" height="8.934" viewBox="0 0 19.449 8.934">
  <g id="Group_4906" data-name="Group 4906" transform="translate(-2.374 -6.599)">
    <path id="Path_3933" data-name="Path 3933" d="M2.549,11H19.2" transform="translate(1)" fill="none" stroke="#c4fff9" strokeLinecap="round" strokeWidth="2" />
    <path id="Path_3934" data-name="Path 3934" d="M19.444,8.012l3.379,3.07-2.317,3.05" transform="translate(-2)" fill="none" stroke="#c4fff9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path id="Path_3935" data-name="Path 3935" d="M22.731,8.012l-3.357,3.07,2.3,3.05" transform="translate(-16)" fill="none" stroke="#c4fff9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </g>
</svg>

export const warning = <svg xmlns="http://www.w3.org/2000/svg" width="15.934" height="13.548" viewBox="0 0 15.934 13.548">
  <path id="Triangle_29" data-name="Triangle 29" d="M10.11,5.746a1.054,1.054,0,0,1,1.949,0l6.67,10.791c.718,1.162.2,2.1-1.168,2.1H4.608c-1.363,0-1.889-.938-1.168-2.1Zm.975,8.566A.62.62,0,0,0,11.7,13.7V9.981a.618.618,0,0,0-1.237,0V13.7A.616.616,0,0,0,11.084,14.312Zm0,2.474a.618.618,0,1,0-.618-.618A.618.618,0,0,0,11.084,16.786Zm0,0" transform="translate(-3.116 -5.094)" fill="#c4fff9" fillRule="evenodd" />
</svg>

export const thresholds = <svg xmlns="http://www.w3.org/2000/svg" width="18.697" height="19.684" viewBox="0 0 18.697 19.684">
  <g id="Group_1434" data-name="Group 1434" transform="translate(-0.494 9.842) rotate(-45)">
    <path id="Path_846" data-name="Path 846" d="M6.127,1.217,4.576,2.768A6.42,6.42,0,0,1,.3,4.372.3.3,0,0,0,.09,4.89l8.241,8.241a.3.3,0,0,0,.518-.206,6.421,6.421,0,0,1,1.6-4.281L12,7.094A4.155,4.155,0,0,0,6.127,1.217Z" transform="translate(0 0.698)" fill="#c4fff9" />
    <path id="Path_847" data-name="Path 847" d="M.608,2.469a2.077,2.077,0,0,0,2.469.351L.257,0A2.077,2.077,0,0,0,.608,2.469Z" transform="translate(2.134 8.708)" fill="#c4fff9" />
    <path id="Path_848" data-name="Path 848" d="M1.9.329A1.121,1.121,0,0,0,0,.914a4.786,4.786,0,0,1,.719.591,4.735,4.735,0,0,1,.589.719A1.121,1.121,0,0,0,1.9.329Z" transform="translate(11.695 0)" fill="#c4fff9" />
  </g>
</svg>

export const note = <svg id="Group_725" data-name="Group 725" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <path id="Ellipse_386" data-name="Ellipse 386" d="M7,1a6,6,0,1,0,6,6A6.007,6.007,0,0,0,7,1M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" fill="#46aaa0" />
  <path id="Path_2764" data-name="Path 2764" d="M.556-4.036H-.5L-.648.4H.705ZM.029-4.73a.712.712,0,0,0,.516-.189A.643.643,0,0,0,.74-5.4a.638.638,0,0,0-.2-.479A.712.712,0,0,0,.029-6.07a.706.706,0,0,0-.512.189.638.638,0,0,0-.2.479.645.645,0,0,0,.2.481A.7.7,0,0,0,.029-4.73Z" transform="translate(7 4) rotate(180)" fill="#46aaa0" />
</svg>

export const depth = <svg xmlns="http://www.w3.org/2000/svg" width="9.16" height="19.449" viewBox="0 0 9.16 19.449">
  <g id="Group_4906" data-name="Group 4906" transform="translate(7.521 1) rotate(90)">
    <path id="Path_3933" data-name="Path 3933" d="M2.549,11H19.2" transform="translate(-2.374 -8.012)" fill="none" stroke="#c4fff9" strokeLinecap="round" strokeWidth="2" />
    <path id="Path_3934" data-name="Path 3934" d="M20.506,7.849l2.317,3.233-2.317,3.05" transform="translate(-5.374 -8.012)" fill="none" stroke="#c4fff9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path id="Path_3935" data-name="Path 3935" d="M21.669,7.764l-2.3,3.318,2.3,3.05" transform="translate(-19.374 -8.012)" fill="none" stroke="#c4fff9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </g>
</svg>

export const confirm = <svg xmlns="http://www.w3.org/2000/svg" width="13.167" height="11.535" viewBox="0 0 13.167 11.535">
  <path id="conPath" d="M75.364,160.23l3.962,3.657,6.4-9.143" transform="translate(-73.951 -153.352)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
</svg>

export const edit = <svg xmlns="http://www.w3.org/2000/svg" width="12.749" height="12.749" viewBox="0 0 12.749 12.749">
  <path id="edit" d="M0,10.058v2.691H2.691l7.791-7.862L7.791,2.2,0,10.058ZM12.537,2.833a.685.685,0,0,0,0-.992L10.907.212a.685.685,0,0,0-.992,0L8.641,1.487l2.691,2.691,1.2-1.346Z" fill="#73e4cc" fillRule="evenodd" />
</svg>

export const editHover = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <path id="Shape" d="M0,9.467V12H2.533L9.867,4.6,7.333,2.067,0,9.467Zm11.8-6.8a.644.644,0,0,0,0-.933L10.267.2a.644.644,0,0,0-.933,0l-1.2,1.2,2.533,2.533L11.8,2.667Z" fill="#175852" fillRule="evenodd" />
</svg>


export const noMeters = <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="370.439" height="302.016" viewBox="0 0 370.439 302.016">
  <defs>
    <linearGradient id="linear-gradient" x1="0.813" y1="0.841" x2="0.813" y2="-0.159" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="gray" stop-opacity="0.251" />
      <stop offset="0.54" stop-color="gray" stop-opacity="0.122" />
      <stop offset="1" stop-color="gray" stop-opacity="0.102" />
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="1.78" y1="0.849" x2="1.78" y2="-0.151" xlinkHref="#linear-gradient" />
    <linearGradient id="linear-gradient-3" x1="0.5" y1="1" x2="0.5" y2="0" xlinkHref="#linear-gradient" />
  </defs>
  <g id="Group_2818" data-name="Group 2818" transform="translate(-1009.434 -271.114)">
    <g id="Group_990" data-name="Group 990" transform="translate(1009.434 315.177)">
      <path id="Path_1154" data-name="Path 1154" d="M263.828,174.155c-22.366-.751-43.668-7.729-64.009-15.359s-40.492-16.1-62.321-20.139c-14.041-2.6-30.1-2.969-41.411,4.3-10.886,7.008-14.4,19.078-16.294,30.3-1.423,8.437-2.26,17.313,1.638,25.213,2.706,5.484,7.513,10.093,10.835,15.346,11.572,18.276,3.391,40.817-9.144,58.658-5.875,8.373-12.7,16.366-17.235,25.27s-6.635,19.121-2.665,28.212c3.932,9.018,13.309,15.78,23.464,20.537,20.627,9.669,44.926,12.434,68.635,14,52.466,3.472,105.216,1.967,157.823.464,19.462-.558,39.023-1.122,58.175-4.031,10.633-1.617,21.616-4.178,29.337-10.37,9.8-7.866,12.223-21.168,5.658-31.021-11.014-16.531-41.467-20.633-49.177-38.375-4.241-9.762.114-20.637,6.276-29.7C376.625,228.043,398.777,211,399.941,188.8c.8-15.254-9.846-30.523-26.309-37.741-17.259-7.562-41.186-6.613-53.911,5.908C306.614,169.856,283.574,174.816,263.828,174.155Z" transform="translate(-57.594 -136.544)" fill="#84d3cb" opacity="0.05" />
      <circle id="Ellipse_400" data-name="Ellipse 400" cx="5.718" cy="5.718" r="5.718" transform="translate(158.893 0.116)" fill="#84d3cb" opacity="0.1" />
      <circle id="Ellipse_401" data-name="Ellipse 401" cx="5.718" cy="5.718" r="5.718" transform="translate(0 0)" fill="#84d3cb" opacity="0.1" />
      <circle id="Ellipse_402" data-name="Ellipse 402" cx="5.718" cy="5.718" r="5.718" transform="translate(334.686 103.842)" fill="#84d3cb" opacity="0.1" />
      <circle id="Ellipse_403" data-name="Ellipse 403" cx="9.582" cy="9.582" r="9.582" transform="translate(351.273 67.315)" fill="#84d3cb" opacity="0.1" />
      <circle id="Ellipse_435" data-name="Ellipse 435" cx="9.582" cy="9.582" r="9.582" transform="translate(194.75 238.788)" fill="#84d3cb" opacity="0.1" />
      <circle id="Ellipse_404" data-name="Ellipse 404" cx="9.582" cy="9.582" r="9.582" transform="translate(351.274 155.699)" fill="#84d3cb" opacity="0.1" />
      <circle id="Ellipse_405" data-name="Ellipse 405" cx="9.582" cy="9.582" r="9.582" transform="translate(6.848 76.992)" fill="#84d3cb" opacity="0.1" />
    </g>
    <g id="no_data" data-name="no data" transform="translate(1057.97 271.114)">
      <rect id="Rectangle_1750" data-name="Rectangle 1750" width="145.659" height="201.254" transform="translate(23.038 45.159) rotate(-12.119)" fill="#004e47" />
      <g id="Group_718" data-name="Group 718" transform="translate(26.161 18.414)" opacity="0.5">
        <rect id="Rectangle_1751" data-name="Rectangle 1751" width="140.737" height="194.452" transform="translate(0 29.555) rotate(-12.119)" fill="#46aaa0" />
      </g>
      <rect id="Rectangle_1752" data-name="Rectangle 1752" width="137.154" height="190.156" transform="translate(28.211 48.993) rotate(-12.119)" fill="#023c36" />
      <rect id="Rectangle_1753" data-name="Rectangle 1753" width="68.04" height="16.481" transform="translate(60.445 33.645) rotate(-12.119)" fill="url(#linear-gradient)" />
      <path id="Path_1124" data-name="Path 1124" d="M445.992,91.48a9.739,9.739,0,1,0,11.332,7.526A9.626,9.626,0,0,0,445.992,91.48Zm3.223,15.032a5.62,5.62,0,1,1,4.186-6.666,5.552,5.552,0,0,1-4.186,6.666Z" transform="translate(-355.661 -80.712)" fill="url(#linear-gradient-2)" />
      <rect id="Rectangle_1754" data-name="Rectangle 1754" width="66.607" height="15.755" transform="translate(60.997 32.804) rotate(-12.119)" fill="#46aaa0" />
      <path id="Path_1125" data-name="Path 1125" d="M446.212,91.481a9.309,9.309,0,1,0,11.055,7.153A9.309,9.309,0,0,0,446.212,91.481Zm3.08,14.366a5.372,5.372,0,1,1,4.126-6.379A5.373,5.373,0,0,1,449.293,105.847Z" transform="translate(-355.884 -80.712)" fill="#46aaa0" />
      <rect id="Rectangle_1755" data-name="Rectangle 1755" width="145.659" height="201.254" transform="translate(85.955 38.966)" fill="#004e47" />
      <g id="Group_719" data-name="Group 719" transform="translate(88.418 42.37)" opacity="0.5">
        <rect id="Rectangle_1756" data-name="Rectangle 1756" width="140.737" height="194.452" fill="#46aaa0" />
      </g>
      <rect id="Rectangle_1757" data-name="Rectangle 1757" width="137.154" height="190.156" transform="translate(90.208 43.802)" fill="#023c36" />
      <rect id="Rectangle_1758" data-name="Rectangle 1758" width="68.04" height="16.481" transform="translate(124.945 35.557)" fill="url(#linear-gradient-3)" />
      <rect id="Rectangle_1759" data-name="Rectangle 1759" width="66.607" height="15.755" transform="translate(125.661 34.85)" fill="#46aaa0" />
      <path id="Path_1126" data-name="Path 1126" d="M647.854,118a9.741,9.741,0,1,0,9.512,9.739A9.628,9.628,0,0,0,647.854,118Zm0,15.359a5.62,5.62,0,1,1,5.486-5.62,5.554,5.554,0,0,1-5.486,5.592Z" transform="translate(-488.891 -98.535)" fill="#46aaa0" />
      <g id="Group_720" data-name="Group 720" transform="translate(2.163 245.66)" opacity="0.5">
        <rect id="Rectangle_1760" data-name="Rectangle 1760" width="1.253" height="7.109" transform="translate(2.927)" fill="#c4fff9" />
        <rect id="Rectangle_1761" data-name="Rectangle 1761" width="1.253" height="7.109" transform="translate(7.109 2.93) rotate(90)" fill="#c4fff9" />
      </g>
      <g id="Group_721" data-name="Group 721" transform="translate(147.556)" opacity="0.5">
        <rect id="Rectangle_1762" data-name="Rectangle 1762" width="1.253" height="7.109" transform="translate(2.927)" fill="#c4fff9" />
        <rect id="Rectangle_1763" data-name="Rectangle 1763" width="1.253" height="7.109" transform="translate(7.109 2.927) rotate(90)" fill="#c4fff9" />
      </g>
      <path id="Path_1128" data-name="Path 1128" d="M950.727,826.463a1.537,1.537,0,0,1-.857-1.857.735.735,0,0,0,.034-.169h0a.77.77,0,0,0-1.384-.51h0a.738.738,0,0,0-.087.15,1.536,1.536,0,0,1-1.856.856.765.765,0,0,0-.171-.033h0a.77.77,0,0,0-.51,1.383h0a.728.728,0,0,0,.15.086,1.536,1.536,0,0,1,.856,1.857.724.724,0,0,0-.033.17h0a.77.77,0,0,0,1.383.51h0a.718.718,0,0,0,.086-.15,1.537,1.537,0,0,1,1.857-.856.727.727,0,0,0,.169.032h0a.77.77,0,0,0,.51-1.383h0A.764.764,0,0,0,950.727,826.463Z" transform="translate(-693.724 -568.968)" fill="#c4fff9" opacity="0.5" />
      <path id="Path_1129" data-name="Path 1129" d="M316.317,621.4a1.537,1.537,0,0,1-.856-1.857.738.738,0,0,0,.034-.171h0a.77.77,0,0,0-1.383-.51h0a.74.74,0,0,0-.086.15,1.537,1.537,0,0,1-1.857.856.739.739,0,0,0-.17-.034h0a.77.77,0,0,0-.51,1.383h0a.743.743,0,0,0,.15.087,1.536,1.536,0,0,1,.856,1.857.739.739,0,0,0-.034.171h0a.77.77,0,0,0,1.383.51h0a.721.721,0,0,0,.086-.15,1.536,1.536,0,0,1,1.857-.856.754.754,0,0,0,.17.034h0a.77.77,0,0,0,.51-1.384h0A.742.742,0,0,0,316.317,621.4Z" transform="translate(-270.764 -432.255)" fill="#209e83" opacity="0.5" />
      <path id="Path_1130" data-name="Path 1130" d="M844.727,122.084a1.537,1.537,0,0,1-.856-1.857.736.736,0,0,0,.033-.17h0a.77.77,0,0,0-1.383-.51h0a.756.756,0,0,0-.087.15,1.535,1.535,0,0,1-1.856.856.743.743,0,0,0-.171-.034h0a.77.77,0,0,0-.51,1.383h0a.732.732,0,0,0,.15.086,1.537,1.537,0,0,1,.856,1.857.751.751,0,0,0-.034.17h0a.77.77,0,0,0,1.383.51h0a.745.745,0,0,0,.087-.15,1.537,1.537,0,0,1,1.857-.856.735.735,0,0,0,.169.034h0a.77.77,0,0,0,.51-1.383h0A.747.747,0,0,0,844.727,122.084Z" transform="translate(-623.055 -99.36)" fill="#209e83" opacity="0.5" />
      <circle id="Ellipse_380" data-name="Ellipse 380" cx="2.51" cy="2.51" r="2.51" transform="translate(268.344 102.304)" fill="#209e83" opacity="0.5" />
      <circle id="Ellipse_381" data-name="Ellipse 381" cx="2.51" cy="2.51" r="2.51" transform="translate(74.393 246.35)" fill="#209e83" opacity="0.5" />
      <circle id="Ellipse_382" data-name="Ellipse 382" cx="2.51" cy="2.51" r="2.51" transform="translate(242.571 156.551)" fill="#209e83" opacity="0.5" />
      <circle id="Ellipse_383" data-name="Ellipse 383" cx="2.51" cy="2.51" r="2.51" transform="translate(26.534 29.604)" fill="#209e83" opacity="0.5" />
      <circle id="Ellipse_384" data-name="Ellipse 384" cx="2.51" cy="2.51" r="2.51" transform="translate(0 98.068)" fill="#209e83" opacity="0.5" />
    </g>
  </g>
</svg>

export const addReading = <svg xmlns="http://www.w3.org/2000/svg" width="31.68" height="26.387" viewBox="0 0 31.68 26.387">
  <g id="Group_4961" data-name="Group 4961" transform="translate(-0.859 -2.521)">
    <g id="Group_4957" data-name="Group 4957" transform="translate(0.51 3)">
      <g id="Group_4955" data-name="Group 4955" transform="matrix(0.616, -0.788, 0.788, 0.616, 6.756, 11.188)">
        <path id="Path_4036" data-name="Path 4036" d="M6.56-.526,3.3,2.905.219-.177" transform="translate(13.907 12.849) rotate(-78)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path id="Path_4035" data-name="Path 4035" d="M6.4,15.9,3.011,8.443l4.261-5.12L3.717-2.764" transform="matrix(0.616, -0.788, 0.788, 0.616, -0.616, 5.125)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
      <g id="Group_4956" data-name="Group 4956" transform="matrix(-0.438, 0.899, -0.899, -0.438, 31.512, 11.22)">
        <path id="Path_4036-2" data-name="Path 4036" d="M6.341,0,3.083,3.432,0,.349" transform="matrix(0.208, -0.978, 0.978, 0.208, 14.379, 14.832)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path id="Path_4035-2" data-name="Path 4035" d="M3.385,18.666,0,11.208l4.261-5.12L.706,0" transform="matrix(0.616, -0.788, 0.788, 0.616, 0, 3.358)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </g>
    <g id="Ellipse_775" data-name="Ellipse 775" transform="translate(0.859 10.832)" fill="#73e4cc" stroke="#02312c" strokeWidth="1.5">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
      <circle cx="7.5" cy="7.5" r="6.75" fill="none" />
    </g>
    <text id="_" data-name="+" transform="translate(3.859 22.832)" fill="#02312c" font-size="16" font-family="LithosPro-Black, Lithos Pro" font-weight="800"><tspan x="0" y="0">+</tspan></text>
  </g>
</svg>


export const addReadingHover = <svg xmlns="http://www.w3.org/2000/svg" width="31.68" height="26.387" viewBox="0 0 31.68 26.387">
  <g id="Group_4961" data-name="Group 4961" transform="translate(-0.859 -2.521)">
    <g id="Group_4957" data-name="Group 4957" transform="translate(0.51 3)">
      <g id="Group_4955" data-name="Group 4955" transform="matrix(0.616, -0.788, 0.788, 0.616, 6.756, 11.188)">
        <path id="Path_4036" data-name="Path 4036" d="M6.56-.526,3.3,2.905.219-.177" transform="translate(13.907 12.849) rotate(-78)" fill="none" stroke="#185650" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path id="Path_4035" data-name="Path 4035" d="M6.4,15.9,3.011,8.443l4.261-5.12L3.717-2.764" transform="matrix(0.616, -0.788, 0.788, 0.616, -0.616, 5.125)" fill="none" stroke="#185650" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
      <g id="Group_4956" data-name="Group 4956" transform="matrix(-0.438, 0.899, -0.899, -0.438, 31.512, 11.22)">
        <path id="Path_4036-2" data-name="Path 4036" d="M6.341,0,3.083,3.432,0,.349" transform="matrix(0.208, -0.978, 0.978, 0.208, 14.379, 14.832)" fill="none" stroke="#185650" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path id="Path_4035-2" data-name="Path 4035" d="M3.385,18.666,0,11.208l4.261-5.12L.706,0" transform="matrix(0.616, -0.788, 0.788, 0.616, 0, 3.358)" fill="none" stroke="#185650" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </g>
    <g id="Ellipse_775" data-name="Ellipse 775" transform="translate(0.859 10.832)" fill="#1c5f58" stroke="#73e4cc" strokeWidth="1.5">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
      <circle cx="7.5" cy="7.5" r="6.75" fill="none" />
    </g>
    <text id="_" data-name="+" transform="translate(4.359 22.482)" fill="#73e4cc" font-size="14" font-family="LithosPro-Black, Lithos Pro" font-weight="800"><tspan x="0" y="0">+</tspan></text>
  </g>
</svg>











