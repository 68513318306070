import { FC } from "react";
import { CMultiSelect } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";
import useSearchDropDownList from "./hooks/useSearchDropDownList";
import SearchDropDownListProps from "./index.interface";

const SearchDropDownList: FC<SearchDropDownListProps> = ({
  options,
  selectedValue,
  onChangeHandler,
  valueWidth,
  valueHeight,
  valueIcon,
  className = "",
  error,
}) => {
  const { t } = useTranslation();
  const { selectedOption, handleChange, toggleDropDownShow } =
    useSearchDropDownList(onChangeHandler, className);
  const valueStyle = { width: valueWidth, height: valueHeight };
  return (
    <div className={`search-drop-down-list ${className}`}>
      <div
        className="value-label"
        style={valueStyle}
        onClick={toggleDropDownShow}
      >
        {valueIcon ? valueIcon : ""}
        {selectedOption[0]
          ? selectedOption[0].text
          : selectedValue
          ? selectedValue
          : ""}
      </div>
      {error && <div className="error mt-2 regular-font">{error}</div>}
      <CMultiSelect
        options={options}
        virtualScroller
        className="search-drop-down-list"
        selectAll={false}
        multiple={false}
        onChange={handleChange}
        searchNoResultsLabel={t("noItemsFound")}
        placeholder={t("search")}
      />
    </div>
  );
};

export default SearchDropDownList;
