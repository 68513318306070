import axios from 'axios';
import {getEnv} from './env'

export const BASE_API = axios.create({
    baseURL: getEnv().API_URL
  });

























































































































































