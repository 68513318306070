import BadgeComponent from "components/BadgeComponent";
import { FC } from "react";
import { geocoderSearchLoop, notify } from "assets/icons/svg/constants";
import {
  point,
  circular,
  rectangular,
} from "assets/icons/svg/FarmRegistration/index";
import { Player } from "@lottiefiles/react-lottie-player";
import CircleGif from "assets/animations/circle.json";
import RectangleGif from "assets/animations/rectangle.json";
import PointGif from "assets/animations/points.json";
import { MapViewProps } from "./index.interface";
import ContactUs from "components/ContactUs";
import DropDownSelector from "components/DropDownSelector";
import { moreInfo } from "assets/icons/svg/MainMap";
import Loader from "components/Loading";
import useMapStates from "./hooks/useMapStates";
import LightDropDownSelector from "components/LightDropDownSelector";
import { MAP_SEARCH_MODES } from "utils/config";
import Input from "components/Input";
import useScreenDimensions from "utils/useScreenDimensions";
import { closeWithBg } from "assets/icons/svg/constants";
import ProceedButton from "components/ProceedButton";
import { SMALL_SCREEN_WIDTH } from "utils/config";

const MapView: FC<MapViewProps> = ({
  handleWGSCoordinates,
  handleCoordinates,
  t,
  mapRef,
  acresUsed,
  organizationDetails,
  enableDrawing,
  drawingMethod,
  setDrawingMethod,
  choicesList,
  currentMapMode,
  setCurrentMapMode,
  firstSearchTime,
  layerLoading,
  setFirstSearchTime,
  isDropdownFocused,
  setIsDropdownFocused,
  selectedSearchMethod,
  handleSelectedSearchMethod,
  wgsCoordinates,
  coordinates
}) => {
  const {
    remaining,
    isDropdownHovered,
    setIsDropdownHovered,
    setControlSelected,
    controlSelected,
    searchMethodsOptions,
  } = useMapStates(
    t,
    organizationDetails,
    acresUsed,
    setFirstSearchTime,
    firstSearchTime,
    isDropdownFocused
  );
  const { screenDimensions } = useScreenDimensions();
  const smallScreen = screenDimensions.width <= SMALL_SCREEN_WIDTH;
  const contactUsTooltipPosition =
    screenDimensions.width <= 850 ? "start" : "end";
  const closeIconHandler = (e: any) => {
    e.stopPropagation();
    setControlSelected("");
    setDrawingMethod("");
  };

  const onButtonClick = (e: any, type: string) => {
    if (smallScreen) {
      setControlSelected(type);
      setDrawingMethod("");
    }
    if (!smallScreen) {
      setDrawingMethod(type);
    }
  };
  const handleDrawButton = (e: any, type: string) => {
    e.stopPropagation();
    setControlSelected("");
    setDrawingMethod(type);
  };
  let mode = Object.keys(MAP_SEARCH_MODES).find(
    (key: string) => MAP_SEARCH_MODES[key] === selectedSearchMethod
  );
  return (
    <div className="farm-registration-map-container">
      <div
        className={enableDrawing ? "map" : "map disable-drawing"}
        ref={mapRef}
        id="farm-registration-map"
      />
      {layerLoading && (
        <div className="map-loader">
          {" "}
          <Loader></Loader>{" "}
        </div>
      )}
      <div className={`search-wrapper ${mode?.toLowerCase()}`}>
        <LightDropDownSelector
          handleSelection={handleSelectedSearchMethod}
          list={searchMethodsOptions}
          selectedItemIndex={selectedSearchMethod}
        />
        <span
          id="geocoder"
          className={
            selectedSearchMethod !== MAP_SEARCH_MODES.WGS &&
            selectedSearchMethod !== MAP_SEARCH_MODES.COORDINATES
              ? "geocoder show"
              : "geocoder hide"
          }
        />
        <div
          className={
            selectedSearchMethod === MAP_SEARCH_MODES.WGS
              ? "wgs-wrapper show"
              : "wgs-wrapper hide"
          }
        >
          <span className="loop-icon">{geocoderSearchLoop}</span>
          <Input
            inputType={"text"}
            title={t("deg")}
            handleChange={(e: any) =>
              handleWGSCoordinates("dN", e.target.value)
            }
            value={wgsCoordinates.dN || ""}
            textLength={"medium"}
            suffix={"°"}
          />
          <Input
            inputType={"text"}
            title={t("minute")}
            handleChange={(e: any) =>
              handleWGSCoordinates("mN", e.target.value)
            }
            value={wgsCoordinates.mN || ""}
            textLength={"medium"}
            suffix="'"
          />
          <Input
            inputType={"text"}
            title={t("sec")}
            handleChange={(e: any) =>
              handleWGSCoordinates("sN", e.target.value)
            }
            value={wgsCoordinates.sN || ""}
            textLength={"medium"}
            suffix={'"'}
          />
          <span>{t("north")}, </span>
          <Input
            inputType={"text"}
            title={t("deg")}
            handleChange={(e: any) =>
              handleWGSCoordinates("dE", e.target.value)
            }
            value={wgsCoordinates.dE || ""}
            textLength={"medium"}
            suffix={"°"}
          />
          <Input
            inputType={"text"}
            title={t("minute")}
            handleChange={(e: any) =>
              handleWGSCoordinates("mE", e.target.value)
            }
            value={wgsCoordinates.mE || ""}
            textLength={"medium"}
            suffix="'"
          />
          <Input
            inputType={"text"}
            title={t("sec")}
            handleChange={(e: any) =>
              handleWGSCoordinates("sE", e.target.value)
            }
            value={wgsCoordinates.sE || ""}
            textLength={"medium"}
            suffix={'"'}
          />
          <span> {t("east")} </span>
        </div>

        <div
          className={
            selectedSearchMethod === MAP_SEARCH_MODES.COORDINATES
              ? "coordinates-wrapper show"
              : "coordinates-wrapper hide"
          }
        >
          <span className="loop-icon">{geocoderSearchLoop}</span>
          <Input
            inputType={"text"}
            title={t("latitude")}
            handleChange={(e: any) => handleCoordinates("lat", e.target.value)}
            textLength={"medium"}
            value={coordinates.lat || ""}
          />
          <span>{t("north")}, </span>
          <Input
            inputType={"text"}
            title={t("longitude")}
            handleChange={(e: any) => handleCoordinates("long", e.target.value)}
            textLength={"medium"}
            value={coordinates.long || ""}
          />
          <span> {t("east")} </span>
        </div>
      </div>

      <BadgeComponent
        badgeIcon={notify(remaining <= 0 ? "#ffb9b9" : "#165f59")}
        badgeText={
          <>
            {t("totalAvailableAcres")}{" "}
            <span style={{ fontFamily: "nunito_extraBold" }}>
              -{" "}
              {t("acresCount", {
                value: remaining > 0 ? remaining : 0,
                total: organizationDetails?.allowedAcres,
              })}
            </span>
          </>
        }
        backgroundColor={remaining <= 0 ? "#F53434CC" : "#FFFFFFD9"}
        wrapperBackgroundColor={remaining <= 0 ? "#EA474787" : "#FFFFFF59"}
        textColor={remaining <= 0 ? "#FFB9B9" : "#165F59"}
        borderColor={remaining <= 0 ? "#FFB5B4" : "#FFFFFF"}
      />
      <div id={"shapes-container"} className="shapes-buttons-container">
        <button
          id={"rectangle-mode"}
          disabled={!enableDrawing}
          onClick={(e) => onButtonClick(e, "draw_rectangle")}
          className={`${drawingMethod === "draw_rectangle" ? "selected" : ""} ${
            controlSelected === "draw_rectangle"
              ? "show-animation"
              : "hide-animation"
          }`}
        >
          {rectangular}
          <div className="triangle" />
          <div className="animation-tooltip">
            {smallScreen && (
              <div className="close-icon" onClick={closeIconHandler}>
                {closeWithBg}
              </div>
            )}
            <Player
              src={RectangleGif}
              className={"gif"}
              background={"#156E67"}
              keepLastFrame={false}
              autoplay
              loop
            />
            <div className="text">
              {t("rectangularShapeDesc")}
              {smallScreen && (
                <ProceedButton
                  title={t("draw")}
                  handleClick={(e: any) =>
                    handleDrawButton(e, "draw_rectangle")
                  }
                  disabled={false}
                  id={"rectangle-mode-draw"}
                />
              )}
            </div>
          </div>
        </button>
        <button
          id="circle-mode"
          disabled={!enableDrawing}
          onClick={(e) => onButtonClick(e, "drag_circle")}
          className={`${drawingMethod === "drag_circle" ? "selected" : ""} ${
            controlSelected === "drag_circle"
              ? "show-animation"
              : "hide-animation"
          }`}
        >
          {circular}
          <div className="triangle" />
          <div className="animation-tooltip">
            {smallScreen && (
              <div className="close-icon" onClick={closeIconHandler}>
                {closeWithBg}
              </div>
            )}
            <Player
              src={CircleGif}
              className={"gif"}
              background={"#156E67"}
              keepLastFrame={false}
              autoplay
              loop
            />
            <div className="text">
              {t("circularShapeDesc")}
              {smallScreen && (
                <ProceedButton
                  title={t("draw")}
                  handleClick={(e: any) => handleDrawButton(e, "drag_circle")}
                  disabled={false}
                  id="circle-mode-draw"
                />
              )}
            </div>
          </div>
        </button>
        <button
          id="point-mode"
          disabled={!enableDrawing}
          onClick={(e) => onButtonClick(e, "draw_polygon")}
          className={`${drawingMethod === "draw_polygon" ? "selected" : ""} ${
            controlSelected === "draw_polygon"
              ? "show-animation"
              : "hide-animation"
          }`}
        >
          {point}
          <div className="triangle" />
          <div className="animation-tooltip">
            {smallScreen && (
              <div className="close-icon" onClick={closeIconHandler}>
                {closeWithBg}
              </div>
            )}
            <Player
              src={PointGif}
              className={"gif"}
              background={"#156E67"}
              keepLastFrame={false}
              autoplay
              loop
            />
            <div className="text">
              {t("pointShapeDesc")}
              {smallScreen && (
                <ProceedButton
                  title={t("draw")}
                  handleClick={(e: any) => handleDrawButton(e, "draw_polygon")}
                  disabled={false}
                  id="point-mode-draw"
                />
              )}
            </div>
          </div>
        </button>
      </div>
      <div className="contact-us-wrapper">
        <ContactUs position={contactUsTooltipPosition} />
      </div>
      <div className="layer-navigation">
        <div className="aside-tooltip">
          {isDropdownHovered && (
            <div className="aside-tooltip-wrapper">
              <div className="aside-triangle"></div>
              <div className="aside-tooltiptext">{t("changeMap")}</div>
            </div>
          )}
        </div>
        <div className="top-tooltip">
          {firstSearchTime && (
            <div className="top-tooltip-wrapper">
              <div className="top-triangle"></div>
              <div className="top-tooltiptext">{t("searchTooltip")}</div>
            </div>
          )}
        </div>
        <DropDownSelector
          setIsButtonHovered={setIsDropdownHovered}
          isButtonHovered={isDropdownHovered}
          choicesList={choicesList}
          wrapperClasses={`${isDropdownHovered ? "text-hovered" : ""}`}
          buttonContent={
            <div className="dropdown-content">
              {moreInfo()}
              <span className="current-choice-text">
                {t("changeMapDropdown")}
              </span>
            </div>
          }
          focus={isDropdownFocused}
          setFocus={setIsDropdownFocused}
          currentChoice={choicesList[+currentMapMode?.id - 1]}
          setCurrentChoice={(value: any) =>
            setCurrentMapMode({
              value: value?.props?.placeholder,
              id: value?.props?.id,
            })
          }
        />
      </div>
    </div>
  );
};

export default MapView;
