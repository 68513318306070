import React, { FC, useState } from "react";
import { proceedArrow, proceedArrowAr } from '../../assets/icons/svg/constants/index'
import { ProceedButtonProps } from './index.interface'
const locale = localStorage.getItem('i18nextLng')

const ProceedButton: FC<ProceedButtonProps> = ({
  title,
  handleClick,
  disabled,
  id = "",
  icon = undefined,
  direction,
  className
}) => {
  const [hover, setHover] = useState(false);

  return (
    <button
      disabled={disabled}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      onClick={disabled ? undefined : handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={disabled ? className ? `proceed-button ${className} disabled` : "proceed-button disabled" 
        : className ? `proceed-button ${className}` : "proceed-button"}
      id={id}
    >
      <span className="proceed-text">{title}</span>
      {icon===null?null:<span className="proceed-icon">
        {icon
          ? icon :
        direction === "rtl"
          ? hover && !disabled
            ? proceedArrowAr("#C4FFF9", "322", "321")
            : proceedArrowAr()
          : direction === "ltr"
          ? hover && !disabled
            ? proceedArrow("#C4FFF9", "322", "321")
            : proceedArrow()
          : locale === "ar"
          ? hover && !disabled
            ? proceedArrowAr("#C4FFF9", "322", "321")
            : proceedArrowAr()
          : hover && !disabled
          ? proceedArrow("#C4FFF9", "322", "321")
          : proceedArrow()}
      </span>}
    </button>
  );
};

export default ProceedButton