import { FC } from 'react'
import BadgeComponentProps from './index.interface'

const BadgeComponent: FC<BadgeComponentProps> = ({wrapperBackgroundColor, badgeText, badgeIcon, backgroundColor, borderColor, textColor }) => {

    return (
        <div className='badge-wrapper' style={{ borderColor: borderColor,backgroundColor:wrapperBackgroundColor }}>
            <div className='badge-container' style={{ backgroundColor: backgroundColor }}>
                {badgeIcon && <span className='badge-icon'>{badgeIcon}</span>}
                <span className='badge-text' style={{ color: textColor }}>{badgeText}</span>
            </div>
        </div>
    )
}
export default BadgeComponent