import {FC}from 'react'

interface RadioButtonProps{
    options:String[];
    currentSelection:number;
    handleSelection:Function;
}
const RadioButton:FC<RadioButtonProps>=({options,handleSelection,currentSelection})=>{

    const radioButtons:any[]=[];

    for(let i=0;i<options.length;i++){
        radioButtons.push(
            <div className={'radio-button-element'} key={i}>
                <button className={'radio-button'} onClick={()=>handleSelection(i)}>
                    {currentSelection===i?<div className={'radio-button-selection'}/>:null}
                </button>
                <label className={'radio-button-label'}>{options[i]}</label>
            </div>
        )
    }
    return(
        <>
            {radioButtons}
        </>    
    )
}
export default RadioButton