    //@ts-nocheck
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import Backend  from "i18next-xhr-backend";


const DETECTION_OPTIONS = {
    order: ['localStorage','navigator'],
    caches: ['localStorage']

  };
  const dir = localStorage.getItem('i18nextLng')==='ar' ? "rtl" : "ltr"
  let lng =  localStorage.getItem('i18nextLng')?localStorage.getItem('i18nextLng'):'en'
  document.documentElement.dir = dir

  if( localStorage.getItem('i18nextLng')===undefined){
    if(dir==="ltr")  localStorage.setItem('i18nextLng','en')
    else localStorage.setItem('i18nextLng','ar')

  }

i18n
  .use(Backend) 
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    load: 'languageOnly',
    detection: DETECTION_OPTIONS,
    lng:lng,
    react: { 
      useSuspense: false //   <---- this will do the magic
    },
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome
    
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/lang/${lng}.json`
    },

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
    
  });

export default i18n;