import React from 'react'

export const Next = function (fill) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="26" viewBox="0 0 23 26">
            <path id="Polygon_67" data-name="Polygon 67" d="M12.129,1.54a1,1,0,0,1,1.741,0L25.157,21.508A1,1,0,0,1,24.286,23H1.714a1,1,0,0,1-.871-1.492Z" transform="translate(23) rotate(90)" fill={fill} />
        </svg>
    )
}

export const Previous = function (fill) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26">
            <path id="Polygon_68" data-name="Polygon 68" d="M12.139,1.457a1,1,0,0,1,1.722,0L25.108,20.491A1,1,0,0,1,24.248,22H1.752a1,1,0,0,1-.861-1.509Z" transform="translate(0 26) rotate(-90)" fill={fill} />
        </svg>
    )
}

export const moreInfo = (fill = "#46aaa0", size="18") => {
    return (
        <svg id="information-mark-circle-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18">
            <path id="information-mark-circle-icon-2" data-name="information-mark-circle-icon" d="M9,0A9,9,0,1,1,2.636,2.636,8.973,8.973,0,0,1,9,0ZM8.641,6.746A.885.885,0,0,1,8,6.48.9.9,0,0,1,8,5.2a.9.9,0,0,1,1.275,0,.886.886,0,0,1,.144,1.091.938.938,0,0,1-.327.329.889.889,0,0,1-.453.123Zm.982,5.286h1.025v1.031h-3.3V12.032H8.362V8.786H7.351V7.755H9.624Z" fill={fill} fillRule="evenodd" />
        </svg>

    )
}

export const newInfoIcon = () =>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_6293" data-name="Group 6293" transform="translate(89 -433)">
                <g id="Ellipse_1343" data-name="Ellipse 1343" transform="translate(-89 433)" fill="rgba(70,170,160,0.1)" stroke="rgba(70,170,160,0.5)" stroke-width="1">
                <circle cx="9" cy="9" r="9" stroke="none"/>
                <circle cx="9" cy="9" r="8.5" fill="none"/>
                </g>
                <path id="Path_4334" data-name="Path 4334" d="M-.864,0H.864V-6.216H-.864ZM.864-7.224V-8.76H-.864v1.536Z" transform="translate(-80 447)" fill="#46aaa0"/>
            </g>
        </svg>
    )
}

export const calendarIcon = (fill,border) => {
    return (
        <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="17.427" height="17.539" viewBox="0 0 17.427 17.539">
        <defs>
            <clipPath id="clip-path">
            <rect width="16.024" height="12.51" fill="none"/>
            </clipPath>
        </defs>
        <g id="Group_541" data-name="Group 541">
            <path id="Path_641" data-name="Path 641" d="M13.761-1H12.8V1.094h.966Zm3.665,0H14.851V2.142H11.706V-1H5.72V2.142H2.444V-1H0V16.539H17.427V-1ZM15.248,14.444H2.178V5.019h13.07ZM4.631-1h-1.1V1.094h1.1Z" transform="translate(0 1)" fill={border}/>
            <rect id="Rectangle_1545" data-name="Rectangle 1545" width="15.266" height="11.763" transform="translate(1.08 4.262)" fill={border}/>
            <g id="Repeat_Grid_4" data-name="Repeat Grid 4" transform="translate(0.322 5.029)" clip-path="url(#clip-path)">
            <g transform="translate(-4 -8)">
                <rect id="Rectangle_1546" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(0 -8)">
                <rect id="Rectangle_1546-2" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(4 -8)">
                <rect id="Rectangle_1546-3" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(8 -8)">
                <rect id="Rectangle_1546-4" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(12 -8)">
                <rect id="Rectangle_1546-5" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(-4 -4)">
                <rect id="Rectangle_1546-6" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(0 -4)">
                <rect id="Rectangle_1546-7" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(4 -4)">
                <rect id="Rectangle_1546-8" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(8 -4)">
                <rect id="Rectangle_1546-9" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(12 -4)">
                <rect id="Rectangle_1546-10" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(-4)">
                <rect id="Rectangle_1546-11" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <rect id="Rectangle_1546-12" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            <g transform="translate(4)">
                <rect id="Rectangle_1546-13" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(8)">
                <rect id="Rectangle_1546-14" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(12)">
                <rect id="Rectangle_1546-15" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(-4 4)">
                <rect id="Rectangle_1546-16" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(0 4)">
                <rect id="Rectangle_1546-17" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(4 4)">
                <rect id="Rectangle_1546-18" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(8 4)">
                <rect id="Rectangle_1546-19" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            <g transform="translate(12 4)">
                <rect id="Rectangle_1546-20" data-name="Rectangle 1546" width="3" height="3" transform="translate(5 8.503)" fill={fill}/>
            </g>
            </g>
            <rect id="Rectangle_1547" data-name="Rectangle 1547" width="17.427" height="1.144" transform="translate(0 3.521)" fill={fill}/>
        </g>
        </svg>

    )
}
export const iconFinder = (fill = "#73E4CC", width = "19", height = "19") => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32.058 32.376">
            <path id="growing-market-analysis-icon" d="M8.749,15.193v4.933a.229.229,0,0,1-.227.227H6.579a.229.229,0,0,1-.227-.227V15.193ZM14.117,0A14.123,14.123,0,0,1,25.936,21.862l6.113,6.661-4.214,3.853-5.895-6.5A14.123,14.123,0,1,1,14.117,0Zm8.871,5.249a12.548,12.548,0,1,0,3.688,8.874A12.548,12.548,0,0,0,22.989,5.249ZM6.347,13.581,11.5,9.071c1.135,1.117,2.247,2.468,3.374,3.585l4.117-4.135L17.676,7.2l3.9-.034v3.951l-1.28-1.3c-.914.925-3.071,2.963-3.975,3.872-1.154,1.154-1.7,1.175-2.86.021l-1.949-2.173-2.3,2.036Zm14.171-1.2v7.746a.229.229,0,0,1-.227.227H18.34a.227.227,0,0,1-.224-.227V14.255l1.246-1.2.914-.89a1.58,1.58,0,0,0,.234.205Zm-3.93,3.245v4.5a.227.227,0,0,1-.224.227H14.418a.227.227,0,0,1-.224-.227V16.107A2.958,2.958,0,0,0,16.588,15.625Zm-3.917-.366v4.868a.227.227,0,0,1-.227.227H10.5a.229.229,0,0,1-.227-.227V14.858a1.3,1.3,0,0,0,.116-.095l.975-.864.79.874.061.063q.237.237.466.435Z" transform="translate(0.008)" fill={fill} fillRule="evenodd" />
        </svg>
    )
}
export const arrow = () => {
    const lang = localStorage.getItem('i18nextLng') || 'en';
    return (
        lang === 'en' ?
            <svg xmlns="http://www.w3.org/2000/svg" width="11.026" height="8.494" viewBox="0 0 11.026 8.494">
                <g id="arrow" transform="translate(-1636.315 -109.68)">
                    <path id="Path_3798" data-name="Path 3798" d="M-865.041,1714.815h8.769" transform="translate(2501.956 -1601)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeWidth="1.2" />
                    <path id="Path_3799" data-name="Path 3799" d="M-855.983,1711.52l4.482,3.406" transform="translate(2498 -1601)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
                    <path id="Path_3800" data-name="Path 3800" d="M-851.5,1711.52l-4.482,3.406" transform="translate(2498 -1597.594)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
                </g>
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="11.026" height="8.494" viewBox="0 0 11.026 8.494">
                <g id="arrow" transform="translate(0.841 0.841)">
                    <path id="Path_3798" data-name="Path 3798" d="M-856.272,1714.815h-8.769" transform="translate(865.857 -1711.52)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeWidth="1.2" />
                    <path id="Path_3799" data-name="Path 3799" d="M-851.5,1711.52l-4.482,3.406" transform="translate(855.982 -1711.52)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
                    <path id="Path_3800" data-name="Path 3800" d="M-855.983,1711.52l4.482,3.406" transform="translate(855.982 -1708.114)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
                </g>
            </svg>
    )
}
export const analysisIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 68 66">
            <g id="Group_4109" data-name="Group 4109" transform="translate(-310 -1866)">
                <rect id="Rectangle_3934" data-name="Rectangle 3934" width="56" height="54" rx="25" transform="translate(316 1872)" fill="#5cc3ad" />
                <g id="Rectangle_3936" data-name="Rectangle 3936" transform="translate(323 1878)" fill="#73e4cc" stroke="#c4fff9" strokeWidth="2">
                    <rect width="42" height="42" rx="5" stroke="none" />
                    <rect x="-1" y="-1" width="44" height="44" rx="6" fill="none" />
                </g>
                <path id="growing-market-analysis-icon" d="M8.749,15.193v4.933a.229.229,0,0,1-.227.227H6.579a.229.229,0,0,1-.227-.227V15.193ZM14.117,0A14.123,14.123,0,0,1,25.936,21.862l6.113,6.661-4.214,3.853-5.895-6.5A14.123,14.123,0,1,1,14.117,0Zm8.871,5.249a12.548,12.548,0,1,0,3.688,8.874A12.548,12.548,0,0,0,22.989,5.249ZM6.347,13.581,11.5,9.071c1.135,1.117,2.247,2.468,3.374,3.585l4.117-4.135L17.676,7.2l3.9-.034v3.951l-1.28-1.3c-.914.925-3.071,2.963-3.975,3.872-1.154,1.154-1.7,1.175-2.86.021l-1.949-2.173-2.3,2.036Zm14.171-1.2v7.746a.229.229,0,0,1-.227.227H18.34a.227.227,0,0,1-.224-.227V14.255l1.246-1.2.914-.89a1.58,1.58,0,0,0,.234.205Zm-3.93,3.245v4.5a.227.227,0,0,1-.224.227H14.418a.227.227,0,0,1-.224-.227V16.107A2.958,2.958,0,0,0,16.588,15.625Zm-3.917-.366v4.868a.227.227,0,0,1-.227.227H10.5a.229.229,0,0,1-.227-.227V14.858a1.3,1.3,0,0,0,.116-.095l.975-.864.79.874.061.063q.237.237.466.435Z" transform="translate(328.008 1882)" fill="#0a4640" fillRule="evenodd" />
            </g>
        </svg>
    )
}
export const minimize = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.56" height="3.24" viewBox="0 0 11.56 3.24">
            <path id="Path_3802" data-name="Path 3802" d="M2.8-9.04v-3.24H14.36v3.24Z" transform="translate(-2.8 12.28)" fill="#165f59" />
        </svg>
    )
}



