import { useState, useEffect } from 'react';

const useScreenDimensions = () => {
  const [screenDimensions, setScreenDimensions] = useState({ 
    height: window.outerHeight,
    width: window.outerWidth
  });

  useEffect(() => {
    const handleResize = () => {
        setScreenDimensions({ 
            height: window.outerHeight,
            width: window.outerWidth
          });
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  return {screenDimensions};
}

export default useScreenDimensions;






