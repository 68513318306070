import React, { useEffect, useRef, useState } from "react";
import {
  HashRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import "./scss/style.scss";
import AppContext from "./context/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorView from "./components/ErrorView";
import { get } from "./services/ApiFetching";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "./store";
import ScrollToTop from "components/ScrollToTop";
import RefreshComponent from "components/RefreshComponent";
import FarmRegistration from "views/FarmRegistration";
import useHandleErrorHook from "utils/useHandleErrorHook";
import { getCookie, useIsLoggedIn } from "utils/SSOUtil";
import useSwitchLang from "components/SwitchLang/hooks/useSwitchLang";
import Loader from "components/Loading";
import { getEnv } from "env";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Redirect = React.lazy(() => import("./views/Redirect"));
const Login = React.lazy(() => import("./views/SignIn/index"));
const UserFarms = React.lazy(() => import("./views/UserFarms/index"));
const UserOrgs = React.lazy(() => import("./views/UserOrganizations"));
const NewCredentials = React.lazy(() => import("./views/NewCredentials/index"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword/index"));
const ResetLink = React.lazy(() => import("./views/ResetLink"));
const LinkExpired = React.lazy(() => import("./views/LinkExpired"));
const NotFound = React.lazy(() => import("./views/NotFound"));
const OrganizationUsers = React.lazy(
  () => import("./views/OrganizationUsers/index")
);
const UnsubscribedUser = React.lazy(
  () => import("./views/SubscriptionEnded/index")
);

const App = () => {
  
  const { handleErrorHelper } = useHandleErrorHook();
  const [isLoading, setLoading] = useState(false);
  const { chooseLang } = useSwitchLang(undefined);
  const isLoggedIn = useIsLoggedIn();
  const { t } = useTranslation();
  const appContentRef = useRef<HTMLDivElement>(null);
  async function getAllPermissions() {
    try {
      const response = await get(`/auth/permissions`);
      return response;
    } catch (error) {
      handleErrorHelper(error);
    }
  }
  const getUserDetails = async () => {
    try {
      const response = await get(`auth/userdetails`);
      if (getEnv().NAMAA_ENV !== "DEV")
        localStorage.setItem(
          "jwtCheck",
          JSON.stringify(getCookie(getEnv().COOKIE_LOGIN_PROPERTY))
        );
      return response;
    } catch (error) {
      handleErrorHelper(error);
    }
  };

  let selectedOrganization = useSelector(
    (reduxState: ReduxState) => reduxState.selectedOrganization
  );
  let isSubscribed = useSelector(
    (reduxState: ReduxState) => reduxState.isSubscribed
  );

  const dispatch = useDispatch();
  const setPermissionsState = (state: any) =>
    dispatch({ type: "set", permissions: state });

  if (
    isLoggedIn &&
    !isLoading &&
    ((getEnv().NAMAA_ENV === "DEV" && !localStorage.getItem("userDetails")) ||
      localStorage.getItem("jwtCheck") !=
        JSON.stringify(getCookie(getEnv().COOKIE_LOGIN_PROPERTY)))
  ) {
    setLoading(true);
    getUserDetails().then((result) => {
      if (result?.data.statusCode === 213) {
        localStorage.setItem(
          "userDetails",
          JSON.stringify(result?.data?.payload)
        );
        chooseLang(
          result.data.payload?.language === "ar"
            ? t("constantArabicTrans")
            : t("constantEnglishTrans")
        ).then(() => {
          // localStorage.setItem('i18nextLng', result.data.payload?.language);
          setLoading(false);
        });
      }
    });
  }

  useEffect(() => {
    if (isLoggedIn && isSubscribed && (selectedOrganization && Object.keys(selectedOrganization).length > 0 )) {
      getAllPermissions().then((result) => {
        if (result?.data.statusCode === 200) {
          setPermissionsState(result?.data.payload);
        }
      });
    }
  }, [isLoggedIn, isSubscribed, selectedOrganization])
 

  const farmPath = isLoggedIn ? (
    selectedOrganization?.id ? (
      <UserFarms />
    ) : (
      <Navigate to="/UserOrgs" replace />
    )
  ) : (
    <Navigate to="/" replace />
  );
  // const defaultPath = isLoggedIn ? <Navigate to="/UserOrgs" replace /> : <Navigate to="/redirect" replace />;
  const defaultPath = isLoggedIn ? (
    <Navigate to="/UserOrgs" replace />
  ) : getEnv().NAMAA_ENV === "DEV" ? (
    <Login />
  ) : (
    <Redirect />
  );


  return isLoading ? (
    <Loader />
  ) : (
    <AppContext>
      <HashRouter>
        <ErrorBoundary
          FallbackComponent={(error: any) => (
            <ErrorView pathName={"Page"} error={error} />
          )}
        >
          <React.Suspense fallback={loading}>
            <RefreshComponent pullRef={appContentRef} />
            <div ref={appContentRef}>
              <Routes>
                <Route path="/" element={defaultPath} />
                <Route path="/redirect" element={<Redirect />} />
                <Route path="/newcredentials" element={<NewCredentials />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/resetlink/:token" element={<ResetLink />} />
                <Route path="/linkexpired/:token" element={<LinkExpired />} />
                <Route
                  path="/UserOrgs"
                  element={
                    isLoggedIn ? <UserOrgs /> : <Navigate to="/" replace />
                  }
                />
                <Route path="/UserFarms" element={farmPath} />
                <Route
                  path="/admin/organization-users"
                  element={
                    isLoggedIn ? (
                      <OrganizationUsers />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/subscription-ended"
                  element={
                    isLoggedIn ? (
                      <UnsubscribedUser />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/add-farm"
                  element={
                    isLoggedIn ? (
                      <FarmRegistration />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<TheLayout />} />
              </Routes>
            </div>
          </React.Suspense>
        </ErrorBoundary>
      </HashRouter>
    </AppContext>
  );
};

export default App;
