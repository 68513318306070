import React ,{FC} from "react";
import {CButton,} from '@coreui/react'
interface Props{
  disabled :boolean
  handleClick:any
  title:string,
  titleFontSize?:any
}

const Button:FC<Props>=({handleClick,disabled,title,titleFontSize})=>
{
    const className = localStorage.getItem('i18nextLng')==='ar'? "CTA-AR":""
    return(
    <CButton className={className} onClick={handleClick} disabled={disabled} color="primary"><label style={{fontSize:titleFontSize!==undefined && titleFontSize}}>{title}</label></CButton>
    );
}

export default Button