import { useEffect, useState } from "react";

const useMapStates = (
  t: Function,
  organizationDetails: any,
  acresUsed: number,
  setFirstSearchTime: Function,
  firstSearchTime: boolean | undefined,
  isDropdownFocused: boolean
) => {
  let remaining: any =
    Number(
      organizationDetails?.allowedAcres -
        (organizationDetails?.consumedAcres + acresUsed)
    ).toFixed(2) || 0;
  const [isDropdownHovered, setIsDropdownHovered] = useState(false);
  const [controlSelected, setControlSelected] = useState("");
  const searchMethodsOptions = [
    {
      name: t("location").toLowerCase(),
      value: (
        <>
          <div className="title">{t("locationName")}</div>
          <div className="subtitle">ex: wadi el natroun</div>
        </>
      ),
    },
    {
      name: t("latLong"),
      value: (
        <>
          <div className="title">{t("latLongFormat")}</div>
          <div className="subtitle">ex: 28394829°, 3849283°</div>
        </>
      ),
    },
    {
      name: t("degMinSec"),
      value: (
        <>
          <div className="title">{t("dmsCoordinates")}</div>
          <div className="subtitle">ex: 28343°12'22'', 38493°10'23''</div>
        </>
      ),
    },
    {
      name: t("sharedLink"),
      value: (
        <>
          <div className="title">{t("sharedUrl")}</div>
          <div className="subtitle">ex: https://goo.gl/maps/CJ1WH1z53B</div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (isDropdownFocused && firstSearchTime) {
      setFirstSearchTime(false);
    }
  }, [isDropdownFocused, firstSearchTime]);
  return {
    remaining,
    isDropdownHovered,
    setIsDropdownHovered,
    controlSelected,
    setControlSelected,
    searchMethodsOptions,
  };
};
export default useMapStates;
