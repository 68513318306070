import { getEnv } from "env";

const useConactUs = () => {
    const handleClick = () =>{
        window.open(getEnv().NAMAA_CONTACT_US, '_blank', 'noreferrer');
    };
    return {
        handleClick
    };
}
 
export default useConactUs;