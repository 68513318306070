import Loader from "components/Loading";
import { FC, useEffect } from "react";

const PullToRefresh: FC<any> = ({ pullRef }) => {
  function addPullLoader() {
    const indicator = document.querySelector(".loader-wrapper") as HTMLElement;
    if (indicator) indicator.style.display = "flex";
  }

  function removePullLoader() {
    const indicator = document.querySelector(".loader-wrapper") as HTMLElement;
    if (indicator) indicator.style.display = "none";
  }
  useEffect(() => {
    const el = pullRef.current;
    if (!el) return;

    // attach the event listener
    el.addEventListener("touchstart", handleTouchStart);

    function handleTouchStart(startEvent: TouchEvent) {
      const el = pullRef.current;
      if (!el || window.scrollY > 0) return;
      // get the initial Y position
      const initialY = startEvent.touches[0].clientY;

      el.addEventListener("touchmove", handleTouchMove);
      el.addEventListener("touchend", handleTouchEnd);

      function handleTouchMove(moveEvent: TouchEvent) {
        const el = pullRef.current;
        if (!el || window.scrollY > 0) return;

        // get the current Y position
        const currentY = moveEvent.touches[0].clientY;

        // get the difference
        const dy = currentY - initialY;

        if (dy >= 200) addPullLoader();

        if (dy < 0) return;

        // now we are using the `appr` function
        el.style.transform = `translateY(${appr(dy)}px)`;
      }

      // more code

      const MAX = 200;
      const k = 0.4;
      function appr(x: number) {
        return MAX * (1 - Math.exp((-k * x) / MAX));
      }

      function handleTouchEnd(endEvent: TouchEvent) {
        const el = pullRef.current;
        if (!el || window.scrollY > 0) return;

        // run the callback
        const y = endEvent.changedTouches[0].clientY;
        const dy = y - initialY;

        if (dy !== 0)
          // return the element to its initial position
          el.style.transform = "translateY(0)";

        // add transition
        el.style.transition = "transform 0.2s";

        if (dy >= 200) {
          removePullLoader();
          window.location.reload();
        }

        // listen for transition end event
        el.addEventListener("transitionend", onTransitionEnd);

        // cleanup
        el.removeEventListener("touchmove", handleTouchMove);
        el.removeEventListener("touchend", handleTouchEnd);
      }

      function onTransitionEnd() {
        const el = pullRef.current;
        if (!el) return;

        // remove transition
        el.style.transition = "";
        el.style.transform = "none";
        // cleanup
        el.removeEventListener("transitionend", onTransitionEnd);
      }
    }

    return () => {
      // don't forget to cleanup
      el.removeEventListener("touchstart", handleTouchStart);
    };
  }, [pullRef.current]);

  return (
    <div
      className="loader-wrapper"
      style={{
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        paddingBlockStart: "20%",
        display: "none",
      }}
    >
      <Loader hasFullHeight={false} />
    </div>
  );
};

export default PullToRefresh;
