import { useEffect, useState } from "react";

const useNewTooltip = (parent:string, position:string) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipStyle, setTooltipStyle] = useState<any>({});
  
  const lang = localStorage.getItem('i18nextLng') || 'en';
  const insetProperty = localStorage.getItem('i18nextLng') ==='ar' ? 'insetInlineEnd' : 'insetInlineStart';
  const mouseOverHandler = () => {
    if(!showTooltip){
       getParentCoordinates();
       setShowTooltip(true); 
    }   
  }
  const mouseEnterHandler = () => {
    if(showTooltip) setShowTooltip(false);    
  }
  if(parent){
    document?.querySelector(parent)?.addEventListener('mouseleave', mouseEnterHandler);
    document?.querySelector(parent)?.addEventListener('mouseover', mouseOverHandler);
  }

  useEffect(() => {
    getParentCoordinates();
  }, [position]);

  useEffect(() => {
      return () => {
        if(parent){
          document?.querySelector(parent)?.removeEventListener('mouseleave', mouseEnterHandler);
          document?.querySelector(parent)?.removeEventListener('mouseover', mouseOverHandler);
        }
      };
  }, []);
  const getParentCoordinates = () => {
    const tooltipWidth = (document?.querySelector('.new-tooltip__wrapper__content') as any)?.offsetWidth;
    const tooltipHeight = (document?.querySelector('.new-tooltip__wrapper__content') as any)?.offsetHeight;
    const parentElement:any = parent && document?.querySelector(parent);
    if(parentElement){
      const parentCoordinates: any = parentElement?.getBoundingClientRect();
      const width:any = parentElement?.offsetWidth;
      const height:any = parentElement?.offsetHeight;
      switch (position) {
        case 'top':
          setTooltipStyle({
            top: parentCoordinates?.y - tooltipHeight - 10,
            [insetProperty]: parentCoordinates?.x + width /2 - tooltipWidth/2
          })
          break;
        case 'bottom':
          setTooltipStyle({
            top: parentCoordinates?.y + height + 10,
            [insetProperty]: parentCoordinates?.x + width /2 - tooltipWidth/2
          })
          break;
        case 'start':
          setTooltipStyle({
            top: parentCoordinates?.y - height + tooltipHeight/2,
            [insetProperty]: lang === 'ar'? parentCoordinates?.x + width + 10 : parentCoordinates?.x - tooltipWidth -10 
          })
          break;
        default:
          setTooltipStyle({
            top: parentCoordinates?.y - height + tooltipHeight/2,
            [insetProperty]: lang === 'ar'? parentCoordinates?.x - tooltipWidth - 10 : parentCoordinates?.x + width + 10
          })
          break;
      }
    }
  };
  return { tooltipStyle, showTooltip };

}

export default useNewTooltip;