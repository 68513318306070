import { FC } from 'react'
import { useTranslation } from 'react-i18next';
import { logoEn, logoAr } from '../assets/icons/svg/constants';
import Button from './Button'
interface Props {
  pathName: string
  error?: any
}

const ErrorView: FC<Props> = ({ pathName, error }) => {
  const lang = localStorage.getItem('i18nextLng')
  const { t } = useTranslation()

  const refreshPage = () => {
    window.location.reload()
  }
  return (
    <div className='Error-Container'>

      <div className='Content-View'>
        {pathName === 'Page' ?
          <div className='namaa-logo'>{lang === 'en' ? logoEn : logoAr}</div>
          : null}
        <div className='Error-Text'>
          <h1>{t('errorViewTitle')}</h1>
          <p>
            {t('errorViewSubtitle')}
            <br />
            <br />
            {t('errorViewParagraph1')}
            <br />
            {t('errorViewParagraph2')}
          </p>
          <div className={'Button-Container'}>
            <Button disabled={false} handleClick={refreshPage} title={t('errorViewMessage')} />
          </div>
        </div>
        <div className='Error-Background'>
        </div>
      </div>
    </div>
  )
}


export default ErrorView