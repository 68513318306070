import React from 'react'

export const irrigationProgram = <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
  <g id="irrigationE" transform="translate(-65)">
    <g id="Ellipse_885" data-name="Ellipse 885" transform="translate(65)" fill="#033631" stroke="rgba(70,170,160,0.1)" strokeWidth="1">
      <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
      <circle cx="18.5" cy="18.5" r="18" fill="none" />
    </g>
    <g id="valves" transform="translate(73.12 14.054)">
      <path id="Path_865" data-name="Path 865" d="M5.046,0H0" transform="translate(2.51 0)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeWidth="2" />
      <path id="Path_766" data-name="Path 766" d="M15.343,19H4.83" transform="translate(-4.83 -8.862)" fill="#efefef" stroke="#73e4cc" strokeLinecap="round" strokeWidth="3" />
      <path id="Path_767" data-name="Path 767" d="M7.83,13.485h5.683l5.547-4.14h8.051" transform="translate(-6.106 -8.51)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeWidth="2" />
    </g>
  </g>
</svg>

export const fertigationProgram = <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="37" height="37" viewBox="0 0 37 37">
  <defs>
    <clipPath id="clipPath">
      <rect id="Rectangle_3518" data-name="Rectangle 3518" width="22" height="22" transform="translate(0.059 -0.058)" fill="#fff" />
    </clipPath>
  </defs>
  <g id="fertigationE" transform="translate(-69.59 0.41)">
    <g id="Ellipse_884" data-name="Ellipse 884" transform="translate(69.59 -0.41)" fill="#033631" stroke="rgba(70,170,160,0.1)" strokeWidth="1">
      <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
      <circle cx="18.5" cy="18.5" r="18" fill="none" />
    </g>
    <g id="Fertigation_icon" data-name="Fertigation icon" transform="translate(77.53 6.648)" clipPath="url(#clipPath)">
      <g id="npk" transform="translate(-1.852 1.726) rotate(-11)">
        <path id="Path_3150" data-name="Path 3150" d="M6.062,0C7.5,6.38,13.5,14.531,3.913,16.9c2.054-1.242,1.656-6.858,1.845-9.006C5.1,10.527,5.414,15.97,2.55,16.7-4.352,10.206,4.806,5.035,6.062,0Z" transform="translate(1.03 4.477) rotate(-29)" fill="#73e4cc" />
        <path id="Path_3151" data-name="Path 3151" d="M0,.355A6.291,6.291,0,0,0,3.513,1.4,5.2,5.2,0,0,0,6.992,0" transform="translate(9.215 18.014) rotate(-17)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeWidth="1" />
        <path id="Path_3152" data-name="Path 3152" d="M0,0A27.572,27.572,0,0,0,2.613,4.11C3.992,5.889,5.528,7.6,5.751,9.279c.675,3.074-.564,4.593-.564,4.593" transform="translate(7.836 1.454) rotate(-14)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeWidth="1" />
        <path id="Path_3153" data-name="Path 3153" d="M0,13.872a27.574,27.574,0,0,1,2.613-4.11c1.378-1.779,2.915-3.492,3.138-5.17C6.426,1.518,5.187,0,5.187,0" transform="translate(12.208 14.659) rotate(150)" fill="none" stroke="#73e4cc" strokeLinecap="round" strokeWidth="1" />
        <g id="Group_3679" data-name="Group 3679" transform="translate(11.32 2.694) rotate(-17)">
          <path id="Path_3154" data-name="Path 3154" d="M0,1.432,1.815,0" transform="translate(0 1.543)" fill="#fff" stroke="#73e4cc" strokeWidth="1" />
          <circle id="Ellipse_1035" data-name="Ellipse 1035" cx="1.102" cy="1.102" r="1.102" transform="translate(1.313 0)" fill="#73e4cc" />
        </g>
        <g id="Group_3680" data-name="Group 3680" transform="matrix(-0.695, -0.719, 0.719, -0.695, 2.443, 14.687)">
          <path id="Path_3155" data-name="Path 3155" d="M0,1.432,1.815,0" transform="translate(0 1.543)" fill="#fff" stroke="#73e4cc" strokeWidth="1" />
          <circle id="Ellipse_1036" data-name="Ellipse 1036" cx="1.102" cy="1.102" r="1.102" transform="translate(1.313 0)" fill="#73e4cc" />
        </g>
        <g id="Group_3681" data-name="Group 3681" transform="translate(15.342 19.621) rotate(115)">
          <path id="Path_3156" data-name="Path 3156" d="M0,1.432,1.815,0" transform="translate(0 1.543)" fill="#fff" stroke="#73e4cc" strokeWidth="1" />
          <circle id="Ellipse_1037" data-name="Ellipse 1037" cx="1.102" cy="1.102" r="1.102" transform="translate(1.313 0)" fill="#73e4cc" />
        </g>
      </g>
    </g>
  </g>
</svg>


export const title = <svg xmlns="http://www.w3.org/2000/svg" width="15.402" height="13.725" viewBox="0 0 15.402 13.725">
  <g id="calendar" transform="translate(0.332)">
    <g id="Group_541" data-name="Group 541" transform="translate(-0.332)">
      <path id="Path_641" data-name="Path 641" d="M11.733-1h-.824V.639h.824Zm3.125,0h-2.2V1.459H9.981V-1h-5.1V1.459H2.084V-1H0V12.725H14.858V-1ZM13,11.086H1.857V3.71H13ZM3.949-1H3.012V.639h.936Z" transform="translate(0.544 1)" fill="#c4fff9" />
      <rect id="Rectangle_1545" data-name="Rectangle 1545" width="14.858" height="10.151" transform="translate(0.544 3.574)" fill="#c4fff9" />
      <rect id="Rectangle_1547" data-name="Rectangle 1547" width="15.402" height="1.043" transform="translate(0 3.28)" fill="#165f59" />
      <path id="Path_3264" data-name="Path 3264" d="M818.98-5317.375l2.307,1.883,2.933-4.715" transform="translate(-813.598 5326)" fill="none" stroke="#165f59" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </g>
  </g>
</svg>

export const successIcon = <svg id="Group_1126" data-name="Group 1126" xmlns="http://www.w3.org/2000/svg" width="39.788" height="39.788" viewBox="0 0 39.788 39.788">
  <circle id="Ellipse_531" data-name="Ellipse 531" cx="19.894" cy="19.894" r="19.894" transform="translate(0)" fill="#003a35" />
  <path id="Path_3284" data-name="Path 3284" d="M11.5,23.781l7.858,5.291,8.573-16.5" transform="matrix(0.978, 0.208, -0.208, 0.978, 4.76, -4.645)" fill="none" stroke="#d5ffe1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5" />
</svg>

export const failureIcon = <svg id="Group_1126" data-name="Group 1126" xmlns="http://www.w3.org/2000/svg" width="39.788" height="41" viewBox="0 0 39.788 41">
  <circle id="Ellipse_531" data-name="Ellipse 531" cx="19.894" cy="19.894" r="19.894" transform="translate(0)" fill="#ffc6c6" />
  <text id="_" data-name="!" transform="translate(16.305 30)" fill="#a54747" font-size="30" font-family="NunitoSans-Bold, Nunito Sans" font-weight="700"><tspan x="0" y="0">!</tspan></text>
</svg>

export const closeSuccess = <svg id="close" xmlns="http://www.w3.org/2000/svg" width="28.663" height="28.663" viewBox="0 0 28.663 28.663">
  <circle id="Ellipse_98" data-name="Ellipse 98" cx="14.332" cy="14.332" r="14.332" fill="#91c6a0" />
  <line id="Line_92" data-name="Line 92" x2="7.643" y2="7.643" transform="translate(10.471 10.472)" fill="none" stroke="#d5ffe1" strokeLinecap="round" strokeWidth="2" />
  <line id="Line_93" data-name="Line 93" x2="7.643" y2="7.643" transform="translate(18.115 10.472) rotate(90)" fill="none" stroke="#d5ffe1" strokeLinecap="round" strokeWidth="2" />
</svg>

export const closeFailure = <svg id="close" xmlns="http://www.w3.org/2000/svg" width="28.663" height="28.663" viewBox="0 0 28.663 28.663">
  <circle id="Ellipse_98" data-name="Ellipse 98" cx="14.332" cy="14.332" r="14.332" fill="#963c3c" />
  <line id="Line_92" data-name="Line 92" x2="7.643" y2="7.643" transform="translate(10.471 10.472)" fill="none" stroke="#f79595" strokeLinecap="round" strokeWidth="2" />
  <line id="Line_93" data-name="Line 93" x2="7.643" y2="7.643" transform="translate(18.115 10.472) rotate(90)" fill="none" stroke="#f79595" strokeLinecap="round" strokeWidth="2" />
</svg>

export const userIcon = <svg xmlns="http://www.w3.org/2000/svg" width="13.483" height="13.561" viewBox="0 0 13.483 13.561">
  <g id="layer1" transform="translate(2.022 -285.101)">
    <path id="path935" d="M4.518,4.252A6.565,6.565,0,0,0,.264,10.338a.431.431,0,0,0,.132.311.456.456,0,0,0,.32.128H13.3a.443.443,0,0,0,.448-.439A6.566,6.566,0,0,0,9.493,4.252a4.1,4.1,0,0,1-4.974,0Z" transform="translate(-2.286 287.884)" fill="#73e4cc" />
    <path id="path940" d="M5.527.266A3.412,3.412,0,1,0,8.94,3.675,3.415,3.415,0,0,0,5.527.266Z" transform="translate(-0.864 284.835)" fill="#73e4cc" />
  </g>
</svg>

export const filterIcon = <svg xmlns="http://www.w3.org/2000/svg" width="13.76" height="13.74" viewBox="0 0 13.76 13.74">
  <path id="iconfinder_1608702_filter_icon" d="M191.038,256.381a.563.563,0,0,0,.137.683l4.811,4.811v7.241a.583.583,0,0,0,.381.576.679.679,0,0,0,.244.049.58.58,0,0,0,.439-.185l2.5-2.5a.6.6,0,0,0,.185-.439v-4.743l4.811-4.811a.563.563,0,0,0,.137-.683A.583.583,0,0,0,204.1,256H191.614A.583.583,0,0,0,191.038,256.381Z" transform="translate(-190.979 -256)" fill="#73e4cc" />
</svg>



export const proceedEn = function (color = "#10534d") {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="10.894" height="11.16" viewBox="0 0 10.894 11.16">
    <g id="proceed" transform="translate(1 1.407)">
      <path id="Path_321" data-name="Path 321" d="M1581.391,4796h8.5" transform="translate(-1581.391 -4791.965)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
      <path id="Path_322" data-name="Path 322" d="M1596.358,4788l4.859,3.979-4.859,4.362" transform="translate(-1592.323 -4788)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
  </svg>)
}

export const proceedAr = function (color = "#10534d") {

  return (<svg xmlns="http://www.w3.org/2000/svg" width="10.894" height="11.16" viewBox="0 0 10.894 11.16">
    <g id="proceed" transform="translate(1 1.407)">
      <path id="Path_321" data-name="Path 321" d="M1589.887,4796h-8.5" transform="translate(-1580.993 -4791.965)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
      <path id="Path_322" data-name="Path 322" d="M1601.217,4788l-4.859,3.979,4.859,4.362" transform="translate(-1596.358 -4788)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
  </svg>)
}




