import {useState} from 'react';
import { IOption } from '../index.interface';
const useSearchDropDownList = (onChangeHandler: Function|undefined, className: string= '') => {
    const [selectedOption, setSelectedOption] = useState<any>({});
    const handleChange = (options : IOption[]) => {
        if (options?.length > 0) { 
            if(onChangeHandler) onChangeHandler(options);
            setSelectedOption(options);
            toggleDropDownShow();
        }
    };
    const toggleDropDownShow = () => {
        const dropdown = document.querySelector(`${className? `.${className} `: ''}.dropdown.picker.form-multi-select.form-multi-select-with-cleaner.search-drop-down-list .dropdown-menu`);
        if(dropdown?.classList.contains('show')) {
            dropdown?.classList.remove('show');
        }
        else dropdown?.classList.add('show');
    }
    return {
        selectedOption,
        handleChange,
        toggleDropDownShow
    };
}
 
export default useSearchDropDownList;
